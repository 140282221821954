import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Box, Container, Typography } from "@mui/material";
// import { Footer, MobileFooter } from "../components/Footer";

import { Textfield as TextField } from "components/Textfield";
import { CustomLoadingButton as Button } from "../components/Button";
import { checkCode } from "../utils/api";

// import styled from "styled-components";
// import mainImg from "../assets/images/main-img.png";
// import LandingPageImage from "../assets/images/LandingPage_img.png";
// import LandingPoll from "../assets/images/LiveLandingPageImg/LandingPoll.png";
// import LandingQuiz from "../assets/images/LiveLandingPageImg/LandingQuiz.png";
// import LandingDeal from "../assets/images/LiveLandingPageImg/LandingDeal.png";
// import LandingPick from "../assets/images/LiveLandingPageImg/LandingPick.png";
import withplusLiveVideoKOR from "assets/videos/withplus-live-kor.mp4";
import pollCardImg from "assets/images/Cards/pollCard.png";
import quizCardImg from "assets/images/Cards/quizCard.png";
import boardCardImg from "assets/images/Cards/boardCard.png";
import pickCardImg from "assets/images/Cards/pickCard.png";
import dealCardImg from "assets/images/Cards/dealCard.png";
import egogramCardImg from "assets/images/Cards/egogramCard.png";
import arrowImg from "assets/images/simpleGames/arrow.png";
import rouletteImg from "assets/images/simpleGames/roulette.png";
import ladderImg from "assets/images/simpleGames/ladder.png";

// css 파일
import "./LandingPage.css";
import classes from "./LandingPageKakao.module.css";

// import "./LandingPageDropdown/DropdownAnimation.css";
import "./style.css";
import { Footer } from "components/Footer";
import Notice from "components/Notice";

// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.css";

// * 랜딩페이지
const LandingPageKakao = (props) => {
  const navigate = useNavigate();
  const depth = window.location.href.split("/");
  const baseURL = `${depth[0]}//${depth[2]}`;

  const [controls, setControls] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const goToContentPage = (e) => {
    const target = e.target.id;
    setTimeout(() => {
      navigate(`/content/${target}`, { state: { state: true } });
    }, 300);
  };

  const onClickDeal = () => {
    setTimeout(() => {
      navigate("/content/deal", { state: { state: true } });
    }, 300);
  };

  const onClickPick = () => {
    setTimeout(() => {
      navigate("/content/pick", { state: { state: true } });
    }, 300);
  };

  const onClickBtn = () => {
    setTimeout(() => {
      navigate("/login", { state: { state: true } });
    }, 300);
  };

  const onClickPoll = () => {
    setTimeout(() => {
      navigate("/content/poll", { state: { state: true } });
    }, 300);
  };

  const onClickQuiz = () => {
    setTimeout(() => {
      navigate("/content/quiz", { state: { state: true } });
    }, 300);
  };

  const onClickBoard = () => {
    setTimeout(() => {
      navigate("/content/board", { state: { state: true } });
    }, 300);
  };

  const handleControlVideo = () => {
    setControls(true);
  };

  const handleCode = (e) => {
    if (isNaN(e.target.value)) {
      return;
    }
    setCode(e.target.value);
  };

  const submitCode = (e) => {
    e.preventDefault();
    setLoading(true);

    if (code === "" || code.length !== 6) {
      setTimeout(() => {
        alert("6자리 코드를 입력해주세요");
        setLoading(false);
      }, 300);

      return;
    }

    checkCode(code, (res) => {
      let data = res.data;
      let project = data.project;

      setTimeout(() => {
        if (data.success) {
          setLoading(false);
          switch (data.type) {
            case "Live Polling":
              // window.location.href = baseURL + `/app/poll/${project.type}/${data.project._id}`;
              window.location.href = baseURL + `/polling/user/${project._id}`;
              break;

            case "Live Quiz":
              window.location.href = baseURL + `/app/quiz/${data.project._id}`;
              break;

            case "Live Pick":
              window.location.href = baseURL + `/pick/user/${data.project._id}`;
              break;

            case "Live Deal":
              window.location.href = baseURL + `/deal/user/${data.project._id}`;
              break;

            case "Egogram":
              window.location.href = baseURL + `/egogram/user/${data.project._id}`;
              break;

            case "Live Board":
              // navigate(`/board/user/${data.project._id}`);
              window.location.href = baseURL + `/board/user/${data.project._id}`;
              break;

            case "Hexagram":
              // navigate(`/board/user/${data.project._id}`);
              window.location.href = baseURL + `/hexagram/user/${data.project._id}`;
              break;

            case "Live Tap":
              window.location.href = baseURL + `/tap/user/${data.project._id}`;
              break;

            default:
              break;
          }
        } else {
          alert("코드를 정확히 입력해주세요.");
          setLoading(false);
        }
      }, 1000);
    });
  };

  // 반응형 웹
  const isPc = useMediaQuery({
    query: "(min-width:1000px)",
  });

  // 반응형 모바일
  const isMobile = useMediaQuery({
    query: "(max-width:1000px)",
  });

  const Banner = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          bgcolor: "#43bbc1",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "10px 20px",
          boxSizing: "border-box",
          gap: "10px",
          zIndex: "1",
          top: 80,
        }}
      >
        <Typography
          sx={{
            font: "600 18px Pretendard",
            color: "#fff",
            wordBreak: "keep-all",
            fontSize: "clamp(12px, 3.8vw, 18px)",
          }}
        >
          <span style={{ font: "800 24px Pretendard", fontSize: isMobile ? "22px" : "24px" }}>
            “라이브 탭”
          </span>
          신규 게임형 콘텐츠 도구 출시!
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Banner />
      {/* <Notice /> */}
      {/* 웹 뷰 */}
      {isPc && (
        <Box sx={{ boxSizing: "border-box", minHeight: "100vh", pt: "80px", position: "relative" }}>
          {/* <Banner /> */}
          <Box
            sx={{
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div className="main-contents">
              {/* 메인화면 */}
              <section className="overview">
                {/** 왼쪽 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <div style={{ width: "100%", minWidth: "500px" }}>
                    <div className={classes["sub-title"]}>가장 쉬운 강의 도구 플랫폼</div>
                    {/** 텍스트 롤링 */}
                    <div id="main-title" style={{ marginTop: "1.3vw" }}>
                      <div id="scroller-container">
                        <div className="scroller_item">교육</div>
                        <div className="scroller_item">학습</div>
                        <div className="scroller_item">몰입</div>
                        <div className="scroller_item">성장</div>
                        <div className="scroller_item">혁신</div>
                        <div className="scroller_item">경쟁</div>
                        <div className="scroller_item">배움</div>
                        <div className="scroller_item">혁신</div>
                        <div className="scroller_item">성장</div>
                        <div className="scroller_item">교육</div>
                      </div>
                    </div>

                    <div style={{ margin: "16px 8px", marginTop: "1vw" }}>
                      <span
                        id="main-title"
                        style={{
                          marginBottom: "1.5vw",
                          lineHeight: "62px",
                        }}
                      >
                        WITHPLUS LIVE
                      </span>
                    </div>

                    <div className={classes.text}>
                      가장 쉽고 편한 교육도구 플랫폼 위드플러스 라이브를 지금 바로 체험해보세요!
                    </div>
                    <button className={classes.button} onClick={onClickBtn}>
                      체험하기
                    </button>
                  </div>
                </div>
                {/* 오른쪽 */}
                <div
                  className="image-container"
                  style={{
                    width: "40vw",
                    marginTop: "1vw",
                  }}
                >
                  <video
                    autoPlay
                    muted
                    loop
                    style={{ width: "100%", height: "100%", cursor: "pointer" }}
                    src={withplusLiveVideoKOR}
                    controls={controls}
                    onMouseOver={handleControlVideo}
                  ></video>
                </div>
              </section>
              {/* 로고 */}
              <Box
                sx={{
                  width: "80%",
                  "@media (max-width: 1100px)": {
                    width: "95%",
                  },
                }}
              >
                <Typography
                  sx={{
                    font: "bold 32px Pretendard",
                    fontWeight: "800",
                    textAlign: "center",
                    mb: "40px",
                  }}
                >
                  강의에 즐거움을 더합니다
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    rowGap: "40px",
                  }}
                >
                  <Box className={classes["card-grid"]}>
                    <Box
                      className={classes["card"]}
                      sx={{
                        background: `url(${pollCardImg})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <Box>
                        <Typography className={classes["title"]}>Live Polling</Typography>

                        <Typography
                          sx={{
                            font: "18px Pretendard",

                            fontSize: "clamp(14px, 1vw, 18px)",
                            wordBreak: "keep-all",
                          }}
                        >
                          참여자의 모바일 기기를 통해 실시간 투표기능을 제공 <br />
                          <br />
                          참여자의 의견을 실시간으로 청취하고 공유하며 <br />
                          참여도와 집중도를 높일 수 있습니다.
                        </Typography>
                      </Box>

                      <button className={classes["card-button"]} onClick={onClickPoll}>
                        상세보기
                      </button>
                    </Box>
                  </Box>

                  <Box className={classes["card-grid"]}>
                    <Box
                      className={classes["card"]}
                      sx={{
                        background: `url(${quizCardImg})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <Box>
                        <Typography className={classes["title"]}>Live Quiz</Typography>

                        <Typography
                          sx={{
                            font: "18px Pretendard",

                            fontSize: "clamp(14px, 1vw, 18px)",
                            wordBreak: "keep-all",
                          }}
                        >
                          다양한 용도로 사용할 수 있는 실시간 퀴즈 생성도구! <br />
                          <br />
                          간단한 절차를 통해 퀴즈를 생성하고
                          <br />
                          수강생들에게 배포할 수 있어 활용도가 높습니다.
                        </Typography>
                      </Box>

                      <button className={classes["card-button"]} onClick={onClickQuiz}>
                        상세보기
                      </button>
                    </Box>
                  </Box>

                  <Box className={classes["card-grid"]}>
                    <Box
                      className={classes["card"]}
                      sx={{
                        background: `url(${boardCardImg})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <Box>
                        <Typography className={classes["title"]}>Live Board</Typography>

                        <Typography
                          sx={{
                            font: "18px Pretendard",

                            fontSize: "clamp(14px, 1vw, 18px)",
                            wordBreak: "keep-all",
                          }}
                        >
                          협업 기능을 통해 의견을 한눈에 볼 수 있는 온라인 게시판 강의 도구 <br />
                          <br />
                          간단한 절차를 통해 게시판(보드)을 생성하고,
                          <br />
                          학습자들이 자유롭게 게시물(의견)을
                          <br />
                          공유할 수 있어 활용도가 높습니다.
                        </Typography>
                      </Box>

                      <button className={classes["card-button"]} onClick={onClickBoard}>
                        상세보기
                      </button>
                    </Box>
                  </Box>

                  <Box className={classes["card-grid"]}>
                    <Box
                      className={classes["card"]}
                      sx={{
                        background: `url(${pickCardImg})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <Box>
                        <Typography className={classes["title"]}>Live Pick</Typography>

                        <Typography
                          sx={{
                            font: "18px Pretendard",

                            fontSize: "clamp(14px, 1vw, 18px)",
                            wordBreak: "keep-all",
                          }}
                        >
                          전략적 의사결정을 위한 최적의 선택과 분석력 함양! <br />
                          <br />
                          Live Pick은 매 턴마다 숫자 경매를 실시하여
                          <br />
                          최종 숫자의 합이 가장 낮은 팀이 승리하는 <br />
                          의사결정 훈련 게임입니다.
                        </Typography>
                      </Box>

                      <button className={classes["card-button"]} onClick={onClickPick}>
                        상세보기
                      </button>
                    </Box>
                  </Box>

                  <Box className={classes["card-grid"]}>
                    <Box
                      className={classes["card"]}
                      sx={{
                        background: `url(${dealCardImg})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <Box>
                        <Typography className={classes["title"]}>Live Deal</Typography>

                        <Typography
                          sx={{
                            font: "18px Pretendard",

                            fontSize: "clamp(14px, 1vw, 18px)",
                            wordBreak: "keep-all",
                          }}
                        >
                          전략적 의사결정을 위한 최적의 선택과 분석력 함양! <br />
                          <br />
                          18장의 카드를 두 장씩 선택하여 제출하는
                          <br />
                          과정에서 협상을 통해 변화에 대응하는
                          <br />
                          의사결정 훈련 게임입니다.
                        </Typography>
                      </Box>

                      <button className={classes["card-button"]} onClick={onClickDeal}>
                        상세보기
                      </button>
                    </Box>
                  </Box>

                  <Box className={classes["card-grid"]}>
                    <Box
                      className={classes["card"]}
                      sx={{
                        background: `url(${egogramCardImg})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <Box>
                        <Typography className={classes["title"]}>Live Egogram</Typography>

                        <Typography
                          sx={{
                            font: "18px Pretendard",

                            fontSize: "clamp(14px, 1vw, 18px)",
                            wordBreak: "keep-all",
                          }}
                        >
                          자신의 성격을 시각적으로 파악 가능한 자아상태 진단 도구 <br />
                          <br />
                          자아 인식과 대인관계에서부터 <br />
                          리더십과 팀 빌딩, 의사 결정에 이르기까지 <br />
                          다양한 측면에서 활용할 수 있습니다
                        </Typography>
                      </Box>

                      <button
                        className={classes["card-button"]}
                        onClick={goToContentPage}
                        id="egogram"
                      >
                        상세보기
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    font: "bold 30px Pretendard",
                    textAlign: "center",
                    mb: "40px",
                    WebkitTextStroke: "0.5px #000",
                  }}
                >
                  간단 게임
                </Typography>

                <Box
                  sx={{
                    bgcolor: "#eee",
                    py: "25px",
                    display: "flex",
                    justifyContent: "center",
                    gap: "30px",
                  }}
                >
                  <Box className={classes["simple-game-card"]}>
                    <Typography sx={{ font: "bold 18px NotoSansKR-Medium" }}>
                      화살표 돌리기
                    </Typography>

                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={arrowImg} alt="arrow" style={{ height: "80%" }} />
                    </Box>

                    <button
                      className={classes["simple-game-button"]}
                      onClick={() => {
                        setTimeout(() => {
                          navigate("/simpleGame/SimpleGameArrowSpinning");
                        }, 300);
                      }}
                    >
                      체험하기
                    </button>
                  </Box>

                  <Box className={classes["simple-game-card"]}>
                    <Typography sx={{ font: "bold 18px NotoSansKR-Medium" }}>원판 룰렛</Typography>

                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={rouletteImg} alt="rouletteImg" style={{ height: "90%" }} />
                    </Box>

                    <button
                      className={classes["simple-game-button"]}
                      onClick={() => {
                        setTimeout(() => {
                          navigate("/simpleGame/SimpleGameRoultte");
                        }, 300);
                      }}
                    >
                      체험하기
                    </button>
                  </Box>

                  <Box className={classes["simple-game-card"]}>
                    <Typography sx={{ font: "bold 18px NotoSansKR-Medium" }}>
                      사다리 타기
                    </Typography>

                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={ladderImg} alt="ladderImg" style={{ height: "90%" }} />
                    </Box>

                    <button
                      className={classes["simple-game-button"]}
                      onClick={() => {
                        setTimeout(() => {
                          navigate("/simpleGame/SimpleGameGhostLeg");
                        }, 300);
                      }}
                    >
                      체험하기
                    </button>
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
          {/* <Footer /> */}
        </Box>
      )}

      {/* 모바일 뷰 */}
      {isMobile && (
        <Container
          disableGutters
          maxWidth="xs"
          className="mobile"
          sx={{ overflowX: "hidden", minWidth: "320px" }}
        >
          {/* <Notice /> */}

          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                {/** 텍스트 롤링 */}
                <span className={classes["sub-title"]}>가장 쉬운 강의 도구 플랫폼</span>
                <div id="main-title">
                  <div id="scroller-container">
                    <div className="scroller_item">교육</div>
                    <div className="scroller_item">학습</div>
                    <div className="scroller_item">몰입</div>
                    <div className="scroller_item">성장</div>
                    <div className="scroller_item">혁신</div>
                    <div className="scroller_item">경쟁</div>
                    <div className="scroller_item">배움</div>
                    <div className="scroller_item">혁신</div>
                    <div className="scroller_item">성장</div>
                    <div className="scroller_item">교육</div>
                  </div>
                </div>
              </div>

              <div>
                <span
                  // id="main-title"
                  style={{
                    fontSize: "clamp(36px, 12vw, 53px)",
                    // fontSize: "42pt",
                    // width: "100%",
                    // fontSize: "12vw",
                    // fontSize: "12.5vw",
                    marginBottom: "2vw",
                    color: "black",
                    fontFamily: "Pretendard",
                    fontWeight: "bold",
                    padding: "0 7.5pt",
                  }}
                >
                  WITHPLUS LIVE
                </span>
              </div>

              {/* <Typography
                sx={{
                  fontFamily: "Pretendard",
                  mt: "10px",
                  color: "#797979aa",
                  fontSize: "clamp(9px, 3vw, 13.5px)",
                  fontWeight: "bold",
                  px: "10px",
                }}
              >
                가장 쉽고 편한 교육도구 플랫폼 위드플러스 라이브를 지금 바로 체험해보세요!
              </Typography> */}

              <Box
                sx={{ display: "flex", gap: "1rem", mt: "1rem", px: "10px" }}
                component="form"
                onSubmit={submitCode}
              >
                <TextField
                  size="small"
                  fullWidth
                  type="tel"
                  placeholder="접속코드 (예: 123456)"
                  value={code}
                  onChange={handleCode}
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                      border: "2px solid #aaaaaa50",
                    },
                  }}
                />

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "white",
                    border: "2px solid #000",
                    color: "black",
                    font: "bold 16px Pretendard",
                    borderRadius: "10px",
                    width: "max-content",
                    px: "15px",
                    boxShadow: "0px 3px 5px 0px rgba(0,0,0,0.3)",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                    "&:active": {
                      boxShadow: "none",
                      transform: "translateY(4px)",
                    },
                  }}
                  onClick={submitCode}
                  loading={loading}
                >
                  입력
                </Button>
              </Box>

              <Box sx={{ mt: "30px" }}>
                <Typography sx={{ font: "bold 24px Pretendard", textAlign: "center" }}>
                  간단 게임
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                    mt: "20px",
                  }}
                >
                  <div
                    className={classes["mobile-simple-game-button"]}
                    onClick={() => {
                      setTimeout(() => {
                        navigate("/simpleGame/SimpleGameArrowSpinning");
                      }, 300);
                    }}
                  >
                    <img src={arrowImg} style={{ width: "35%" }} />
                  </div>

                  <div
                    className={classes["mobile-simple-game-button"]}
                    onClick={() => {
                      setTimeout(() => {
                        navigate("/simpleGame/SimpleGameRoultte");
                      }, 300);
                    }}
                  >
                    <img src={rouletteImg} style={{ width: "90%" }} />
                  </div>

                  <div
                    className={classes["mobile-simple-game-button"]}
                    onClick={() => {
                      setTimeout(() => {
                        navigate("/simpleGame/SimpleGameGhostLeg");
                      }, 300);
                    }}
                  >
                    <img src={ladderImg} style={{ width: "70%" }} />
                  </div>
                </Box>

                <Typography
                  sx={{
                    textAlign: "center",
                    mt: "20px",
                    color: "#797979aa",
                    font: "bold 13px Pretendard",
                  }}
                >
                  아이콘을 클릭하시면 바로 간단게임을 진행할 수 있습니다.
                </Typography>
              </Box>

              <Box
                sx={{
                  mt: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: "20px",
                  p: 0,
                }}
              >
                <Typography
                  sx={{
                    font: "bold 25px Pretendard",
                    textAlign: "center",
                    mb: "40px",
                  }}
                >
                  강의에 즐거움을 더합니다
                </Typography>

                <Box
                  className={classes["card"]}
                  sx={{
                    background: `url(${pollCardImg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Box className={classes.content}>
                    <Typography className={classes["title"]}>Live Polling</Typography>

                    <p className={classes["card-content"]}>
                      참여자의 모바일 기기를 통해 실시간 투표기능을 제공 <br />
                      <br />
                      참여자의 의견을 실시간으로 청취하고 공유하며 <br />
                      참여도와 집중도를 높일 수 있습니다.
                    </p>
                  </Box>

                  <button className={classes["card-button"]} onClick={onClickPoll}>
                    상세보기
                  </button>
                </Box>

                <Box
                  className={classes["card"]}
                  sx={{
                    background: `url(${quizCardImg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Box className={classes.content}>
                    <Typography className={classes["title"]}>Live Quiz</Typography>

                    <p className={classes["card-content"]}>
                      다양한 용도로 사용할 수 있는 실시간 퀴즈 생성도구! <br />
                      <br />
                      간단한 절차를 통해 퀴즈를 생성하고
                      <br />
                      수강생들에게 배포할 수 있어 활용도가 높습니다.
                    </p>
                  </Box>

                  <button className={classes["card-button"]} onClick={onClickQuiz}>
                    상세보기
                  </button>
                </Box>

                <Box
                  className={classes["card"]}
                  sx={{
                    background: `url(${boardCardImg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Box className={classes.content}>
                    <Typography className={classes["title"]}>Live Board</Typography>

                    <p className={classes["card-content"]}>
                      협업 기능을 통해 의견을 한눈에 볼 수 있는 온라인 게시판 강의 도구 <br />
                      <br />
                      간단한 절차를 통해 게시판(보드)을 생성하고,
                      <br />
                      학습자들이 자유롭게 게시물(의견)을
                      <br />
                      공유할 수 있어 활용도가 높습니다.
                    </p>
                  </Box>

                  <button className={classes["card-button"]} onClick={onClickBoard}>
                    상세보기
                  </button>
                </Box>

                <Box
                  className={classes["card"]}
                  sx={{
                    background: `url(${pickCardImg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Box className={classes.content}>
                    <Typography className={classes["title"]}>Live Pick</Typography>

                    <p className={classes["card-content"]}>
                      전략적 의사결정을 위한 최적의 선택과 분석력 함양! <br />
                      <br />
                      Live Pick은 매 턴마다 숫자 경매를 실시하여
                      <br />
                      최종 숫자의 합이 가장 낮은 팀이 승리하는 <br />
                      의사결정 훈련 게임입니다.
                    </p>
                  </Box>

                  <button className={classes["card-button"]} onClick={onClickPick}>
                    상세보기
                  </button>
                </Box>

                <Box
                  className={classes["card"]}
                  sx={{
                    background: `url(${dealCardImg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Box className={classes.content}>
                    <Typography className={classes["title"]}>Live Deal</Typography>

                    <p className={classes["card-content"]}>
                      전략적 의사결정을 위한 최적의 선택과 분석력 함양! <br />
                      <br />
                      18장의 카드를 두 장씩 선택하여 제출하는
                      <br />
                      과정에서 협상을 통해 변화에 대응하는
                      <br />
                      의사결정 훈련 게임입니다.
                    </p>
                  </Box>

                  <button className={classes["card-button"]} onClick={onClickDeal}>
                    상세보기
                  </button>
                </Box>

                <Box
                  className={classes["card"]}
                  sx={{
                    background: `url(${egogramCardImg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Box className={classes.content}>
                    <Typography className={classes["title"]}>Live Egogram</Typography>

                    <p className={classes["card-content"]}>
                      자신의 성격을 시각적으로 파악 가능한 자아상태 진단 도구 <br />
                      <br />
                      자아 인식과 대인관계에서부터 <br />
                      리더십과 팀 빌딩, 의사 결정에 이르기까지 <br />
                      다양한 측면에서 활용할 수 있습니다
                    </p>
                  </Box>

                  <button className={classes["card-button"]} onClick={goToContentPage} id="egogram">
                    상세보기
                  </button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
      <Footer />
    </>
  );
};

export default LandingPageKakao;

import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import MainImg from "../../assets/images/HexagramMainImg.png";

import {
  Modal,
  Box,
  Typography,
  IconButton,
  InputAdornment,
  Avatar,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import PendingIcon from "@mui/icons-material/Pending";

import styles from "./Style";
import { CustomLoadingButton as Button } from "components/Button";
import { Textfield } from "components/Textfield";
import { SnackBarContext } from "components/SnackBar/ContextAPI";
import * as HAPI from "utils/api/Hexagram";
import * as TAPI from "utils/api/Tap";
import { useNavigate } from "react-router-dom";

import liveTapImg from "assets/images/LiveTap/background2.png";
import liveTapBg from "assets/images/LiveTap_new/Intro.png";

export const DeleteQuizModal = (props) => {
  const { open, onClose, checkedList, action, loading } = props;

  return (
    <Modal open={open} sx={styles.modal} onClose={onClose}>
      <Box sx={styles.content_box}>
        <Typography sx={styles.title}>
          {checkedList.length === 1 ? "해당 퀴즈를 " : `${checkedList.length}개의 퀴즈들을 `}
          삭제하시겠습니까?
        </Typography>

        <Typography sx={styles.explain}>
          해당 퀴즈 데이터들은 영구적으로 삭제되고, 복원할 수 없습니다.
        </Typography>

        <Box sx={styles.button_box}>
          <Button sx={styles.button} onClick={action} loading={loading}>
            삭제
          </Button>

          <Button sx={{ ...styles.button, ...styles.cancel }} onClick={onClose} loading={loading}>
            취소
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AddMemberModal = (props) => {
  const { open, onClose } = props;
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);
  const [cookies] = useCookies();

  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [searching, setSearching] = useState(false);
  const [isUserSelected, setUserSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const searchUser = (e) => {
    if ((e._reactName === "onKeyPress" && e.key === "Enter") || e._reactName === "onClick") {
      if (email.includes("@")) {
        setSearching(true);
      } else {
        alert("이메일 형식이 올바르지 않습니다.");
      }
    }
  };

  const selectUser = (e) => {
    let id = e.currentTarget.getAttribute("id");
    setUserSelected(true);
    setUsers((prev) => prev.filter((user) => user._id === id));
  };

  const resetSearch = () => {
    setUserSelected(false);
    setUsers([]);
    setEmail("");
  };

  const addMember = () => {
    let member = users[0];

    if (member.plan !== "free") {
      alert(
        " 해당 사용자는 유료 구독 모델을 사용중이라 \n 해당 사용자를 그룹에 추가할 수 없습니다. \n 아래의 유선으로 문의해주세요. \n (031 - 423 - 0201)"
      );
      return;
    }

    setLoading(true);

    axios
      .get("/api/group/add", { params: { memberId: member._id, groupAccountToken: cookies.auth } })
      .then((res) => {
        console.log(res);
        let { success } = res.data;
        setTimeout(() => {
          setLoading(false);

          if (success) {
            setOpen(true);
            setSeverity("success");
            setText("해당 멤버를 그룹에 추가했습니다.");
            onClose("add_success");
          } else {
            alert(" 로그인이 만료되어 멤버 추가에 실패했습니다. \n 로그인 후 다시 시도해주세요.");
            window.location.reload();
          }
        }, 1000);
      });
  };

  useEffect(() => {
    if (searching) {
      axios
        .get("/api/group/search", {
          params: {
            email: email,
          },
        })
        .then((res) => {
          let { success, users } = res.data;

          if (success) {
            setTimeout(() => {
              if (users.length > 5) {
                setUsers(users.slice(0, 5));
              } else {
                setUsers(users);
              }
            }, 1000);
          } else {
            alert("해당 유저를 찾을 수 없습니다.");
          }
          setTimeout(() => {
            setSearching(false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [searching]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          bgcolor: "white",
          position: "absolute",
          left: "50%",
          top: "300px",
          transform: "translate(-50%, -50%)",
          outline: 0,
          width: "50%",
          borderRadius: "10px",
          p: "15px 25px",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              color: "white",
              bgcolor: "#c33c3c",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PersonAddIcon sx={{ fontSize: "1.8rem" }} />
          </Box>
          <Typography sx={{ font: "600 24px Pretendard" }}>
            그룹에 추가할 멤버 이메일을 입력 후 선택해주세요.
          </Typography>
          {!isUserSelected && (
            <Box sx={{ width: "100%", mt: "15px" }}>
              <Textfield
                fullWidth
                size="small"
                placeholder="추가할 멤버의 이메일을 입력해주세요."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {searching ? (
                        <PendingIcon
                          sx={{
                            "@keyframes blink": {
                              "0%": {
                                opacity: 0.6,
                              },
                              "50%": {
                                opacity: 1,
                              },
                              "100%": {
                                opacity: 0.6,
                              },
                            },
                            animation: "blink .75s ease-in-out infinite",
                          }}
                        />
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton sx={{ font: "600 16px Pretendard" }} onClick={searchUser}>
                        엔터키 입력 또는 클릭
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "6px",
                  },
                }}
                value={email}
                onChange={handleEmail}
                onKeyPress={searchUser}
              />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              width: "100%",
              pr: "10px",
              boxSizing: "border-box",
              minHeight: "50px",
              maxHeight: "200px",
              overflow: "auto",
            }}
          >
            {users.map((user) => (
              <Box
                key={user._id}
                id={user._id}
                sx={{
                  p: "10px 20px",
                  boxSizing: "border-box",
                  borderRadius: "10px",
                  border: isUserSelected ? "2px solid #0000ff" : "1px solid #aaa",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: isUserSelected ? "auto" : "pointer",
                  "&:hover": {
                    bgcolor: isUserSelected ? "#fff" : "#e5e5e5",
                  },
                }}
                onClick={isUserSelected ? () => {} : selectUser}
              >
                <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <Avatar>{user.name.slice(0, 1)}</Avatar>

                  <Box>
                    <Typography sx={{ font: "500 19px Pretendard", ml: "10px" }}>
                      {user.name}
                    </Typography>
                    <Typography sx={{ font: "400 15px Pretendard", ml: "10px", color: "#888" }}>
                      {user.email}
                    </Typography>
                  </Box>
                </Box>

                {isUserSelected && (
                  <Box
                    sx={{
                      cursor: "pointer",
                      borderRadius: "6px",
                      "&:hover": { bgcolor: "#f5f5f5" },
                      p: "5px",
                    }}
                    onClick={resetSearch}
                  >
                    <Typography sx={{ font: "500 16px Pretendard", color: "#777" }}>
                      다시 선택하기
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Box>

          <Box sx={{ width: "100%", mt: "20px" }}>
            <Button
              fullWidth
              disabled={!isUserSelected}
              sx={{
                font: "600 16px Pretendard",
                py: "10px",
              }}
              onClick={addMember}
              loading={loading}
            >
              {isUserSelected
                ? `'${users[0].name}' 님을 그룹에 추가합니다.`
                : `추가할 멤버를 선택해주세요.`}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export const DeleteMemberModal = (props) => {
  const { open, onClose, checkedList, getMembers } = props;
  const [cookies] = useCookies();
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const [loading, setLoading] = useState(false);

  const removeUsers = () => {
    setLoading(true);

    axios
      .get("/api/group/remove", {
        params: {
          groupAccountToken: cookies.auth,
          members: checkedList,
        },
      })
      .then((res) => {
        let { success } = res.data;

        setTimeout(() => {
          setLoading(false);

          if (success) {
            setOpen(true);
            setSeverity("success");
            setText("해당 멤버를 그룹에서 제외했습니다.");
            onClose({ currentTarget: { id: "" } });
            getMembers();
          } else {
            alert(" 로그인이 만료되어 멤버 제거에 실패했습니다. \n 로그인 후 다시 시도해주세요.");
            window.location.reload();
          }
        }, 500);
      });
  };

  return (
    <Modal open={open} sx={styles.modal} onClose={onClose}>
      <Box
        sx={{
          bgcolor: "white",
          position: "absolute",
          left: "50%",
          top: "300px",
          transform: "translate(-50%, -50%)",
          outline: 0,
          width: "50%",
          borderRadius: "10px",
          // p: "15px 25px",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: "#f5f5f5",
            p: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ font: "800 24px Pretendard" }}>
            해당 멤버를 그룹에서 제외하시겠습니까?
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ p: "20px 10px", boxSizing: "border-box" }}>
          <Typography sx={{ font: "600 17px Pretendard", p: "10px", lineHeight: "1.5" }}>
            <span style={{ font: "800 20px Pretendard", lineHeight: "1.5" }}>
              {checkedList.length === 1
                ? `'${checkedList[0].name}' 님을 `
                : `${checkedList.length}명의 멤버들을 `}
            </span>
            그룹에서 제외시키면, 해당 멤버{checkedList.length === 1 ? "는 " : "들은 "} 단체 계정에
            적용된 구독 모델을 사용할 수 없게 됩니다. <br />
            해당 멤버{checkedList.length === 1 ? "가 " : "들이 "} 그룹에서 제외되어도, 그룹에 다시
            추가할 수 있습니다.
          </Typography>
        </Box>

        <Box sx={{ width: "100%", p: "5px", boxSizing: "border-box" }}>
          <Button
            fullWidth
            sx={{ font: "500 18px Pretendard", lineHeight: "1.5" }}
            loading={loading}
            onClick={removeUsers}
          >
            {checkedList.length === 1
              ? `'${checkedList[0].name}' 님을 그룹에서 제외합니다.`
              : `${checkedList.length}명의 멤버들을 그룹에서 제외합니다.`}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

//0704 지원 핵사그램 모달 추가

export const AddHexagramModal = (props) => {
  const { open, onClose, getList, hexagramPoint, setHexagramPoint } = props;
  const { setOpen, setSeverity, setText, setOrigin } = useContext(SnackBarContext);
  const [cookies] = useCookies();
  const [opacity, setOpacity] = useState(0);
  const [style, setStyle] = useState({ transform: "scale(0)", opacity: 0 });
  const [name, setName] = useState("");
  const [token, setToken] = useState(0);
  const [isName, setIsName] = useState(false);
  const [isToken, setIsToken] = useState(false);
  const [isTokenNum, setIsTokenNum] = useState(false);
  const navigate = useNavigate();

  const handleName = (e) => {
    setName(e.target.value);
    setIsName(e.target.value.length > 0);
  };

  const handleToken = (e) => {
    setToken(e.target.value);
    setIsToken(e.target.value.length > 0);
    setIsTokenNum(!isNaN(e.target.value));
  };

  const handleSubmit = () => {
    if (!isName) {
      setOpen(true);
      setSeverity("error");
      setText("이름을 입력해주세요.");
      return;
    }
    if (!isToken) {
      setOpen(true);
      setSeverity("error");
      setText("토큰 값을 입력해주세요.");
      return;
    }
    if (!isTokenNum) {
      setOpen(true);
      setSeverity("error");
      setText("토큰 값을 숫자로 입력해주세요.");
      return;
    }
    if (parseInt(token) > hexagramPoint) {
      setOpen(true);
      setSeverity("error");
      setText(
        `사용할 수 있는 토큰 갯수보다 많이 입력했습니다. (현재 가지고 있는 토큰 갯수: ${hexagramPoint}개)`
      );
      return;
    }

    HAPI.POST_USE_TOKENS(cookies.auth, parseInt(token)).then((res) => {
      const { success, hexagramPoint: updatedHexagramPoint } = res.data;
      if (success) {
        HAPI.POST_ADD_HEXAGRAM(cookies.auth, name, token).then((res) => {
          const { success, hexagram } = res.data;
          if (success) {
            HAPI.POST_UPDATE_HEXAGRAM_TOKEN(cookies.auth, hexagram._id, parseInt(token)).then(
              (res) => {
                if (res.data.success) {
                  setOpen(true);
                  setSeverity("success");
                  setText("핵사그렘이 추가되었습니다.");
                  onClose();
                  setTimeout(() => {
                    getList();
                  }, 1500);
                } else {
                  alert("핵사그렘 추가에 실패했습니다. 다시 시도해주세요.");
                  window.location.reload();
                }
              }
            );
            // 사용 후 갱신된 hexagramPoint를 업데이트
            setHexagramPoint(updatedHexagramPoint);
            // 입력 필드 초기화
            resetForm();
          } else {
            alert("핵사그렘 추가에 실패했습니다. 다시 시도해주세요.");
            window.location.reload();
          }
        });
      } else {
        setOpen(true);
        setSeverity("error");
        setText("토큰 사용에 실패했습니다.");
      }
    });
  };

  const resetForm = () => {
    setName("");
    setToken(0);
    setIsName(false);
    setIsToken(false);
    setIsTokenNum(false);
  };

  useEffect(() => {
    if (open) {
      setOpacity(1);
      setStyle({ transform: "scale(1)", opacity: 1 });
    } else {
      setOpacity(0);
      setStyle({ transform: "scale(0)", opacity: 0 });
      resetForm(); // 모달이 닫힐 때 입력 필드 초기화
    }
  }, [open]);

  useEffect(() => {
    HAPI.GET_LIST_HEXAGRAM(cookies.auth).then((res) => {
      // Fetching list if needed
    });
  }, []);

  const handleChargeTokens = () => {
    navigate("/diagnosis-plan");
  };

  return (
    <Modal
      open={open}
      sx={{
        opacity: opacity,
        transition: "opacity 0.5s",
        "& .MuiBackdrop-root": { bgcolor: "rgba(0, 0, 0, 0.25)" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "70%",
          minHeight: "550px",
          aspectRatio: "1/1.5",
          ...style,
          bgcolor: "white",
          transition: "all 1s",
          transitionDelay: "0.25s",
          borderRadius: "10px",
          padding: "10px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography sx={{ color: "#c33c3c", font: "bold 24px Pretendard" }}>
          Live Hexagram
        </Typography>

        <Box sx={{ gap: "20px", display: "flex", flexDirection: "column", flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "auto",
              width: "90%",
              margin: "0 auto",
            }}
          >
            <img
              src={MainImg}
              alt="Hexagram"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          <TextField
            fullWidth
            className="br-10"
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ font: "bold 20px Pretendard" }}>Name :</Typography>
                </InputAdornment>
              ),
              style: {
                fontSize: "16px",
                fontFamily: "Pretendard",
                fontWeight: 600,
                backgroundColor: "#ddd",
                borderRadius: "10px",
                color: "#454545",
              },
              inputProps: {
                style: {
                  padding: "16px",
                },
              },
            }}
            placeholder="게임 제목을 입력해주세요."
            value={name}
            onChange={handleName}
          />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography sx={{ font: "bold 20px Pretendard", color: "#333" }}>
                사용할 토큰 개수 :
              </Typography>

              <TextField
                InputProps={{
                  style: {
                    backgroundColor: "#ddd",
                    width: "100px",
                    fontSize: "20px",
                  },
                  inputProps: {
                    style: {
                      padding: "11px",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography sx={{ font: "bold 20px Pretendard" }}>(개)</Typography>
                    </InputAdornment>
                  ),
                }}
                value={token}
                onChange={handleToken}
              />
            </Box>
            <Typography sx={{ color: "#777", fontSize: "14px", textAlign: "right" }}>
              보유한 토큰 갯수: {hexagramPoint}개
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "right",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleChargeTokens}
            >
              토큰 충전하기
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            fullWidth
            sx={{
              bgcolor: "#ababab",
              "&:hover": { bgcolor: "#9a9aab" },
              boxShadow: "2px 2px 6px #ababab",
            }}
            onClick={() => {
              resetForm();
              onClose();
            }}
          >
            취소
          </Button>
          <Button fullWidth sx={{ boxShadow: "2px 2px 6px #ababab" }} onClick={handleSubmit}>
            저장하기
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AddTapModal = (props) => {
  const { open, onClose, getList } = props;
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);
  const [opacity, setOpacity] = useState(0);
  const [style, setStyle] = useState({ transform: "scale(0)", opacity: 0 });
  const [name, setName] = useState("");
  const [gameMode, setGameMode] = useState("team");
  const [teamCount, setTeamCount] = useState(2);
  const [errors, setErrors] = useState({});
  const [cookies] = useCookies();
  // const navigate = useNavigate();

  const [plan, setPlan] = useState("free");

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleGameModeChange = (e) => {
    setGameMode(e.target.value);
  };

  const handleTeamCountChange = (e) => {
    setTeamCount(e.target.value);
  };

  const handleSubmit = async () => {
    if (!name) {
      setOpen(true);
      setSeverity("error");
      setText("이름을 입력해주세요.");
      return;
    }
    TAPI.POST_ADD_TAP(cookies.auth, name, gameMode, gameMode === "team" ? teamCount : 0).then(
      (res) => {
        const { success, message } = res.data;
        if (success) {
          setOpen(true);
          setSeverity("success");
          setText("라이브 탭이 추가되었습니다.");
          onClose();
          setTimeout(() => {
            getList();
          }, 1500);
        } else if (message === "EXCEED_LIMIT") {
          setOpen(true);
          setSeverity("error");
          setText("무료 플랜은 최대 슬롯은 3개입니다.");
        } else {
          alert("라이브 탭 추가에 실패했습니다. 다시 시도해주세요.");
          window.location.reload();
        }
      }
    );
  };

  const resetForm = () => {
    setName("");
    setGameMode("team");
    setTeamCount(2);
    setErrors({});
  };

  useEffect(() => {
    if (open) {
      setOpacity(1);
      setStyle({ transform: "scale(1)", opacity: 1 });

      axios.get("/api/user", { params: { auth: cookies.auth } }).then((res) => {
        let { success, user } = res.data;

        setPlan(user.plan);
      });
    } else {
      setOpacity(0);
      setStyle({ transform: "scale(0)", opacity: 0 });
      resetForm(); // 모달이 닫힐 때 입력 필드 초기화
    }
  }, [open]);

  return (
    <Modal
      open={open}
      sx={{
        opacity: opacity,
        transition: "opacity 0.5s",
        "& .MuiBackdrop-root": { bgcolor: "rgba(0, 0, 0, 0.25)" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "70%",
          minHeight: "700px",
          aspectRatio: "1/1.7",
          ...style,
          bgcolor: "white",
          transition: "all 1s",
          transitionDelay: "0.25s",
          borderRadius: "10px",
          padding: "10px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography sx={{ color: "#c33c3c", font: "bold 24px Pretendard" }}>Live Tap</Typography>
        <Box
          sx={{
            borderRadius: "8px",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={liveTapBg} alt="Live Tap" style={{ width: "100%" }} />
        </Box>
        <Box sx={{ gap: "5px", display: "flex", flexDirection: "column", flex: 1 }}>
          <TextField
            fullWidth
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ font: "bold 20px Pretendard" }}>Name :</Typography>
                </InputAdornment>
              ),
              style: {
                fontSize: "16px",
                fontFamily: "Pretendard",
                fontWeight: 600,
                backgroundColor: "#ddd",
                borderRadius: "10px",
                color: "#454545",
                marginBottom: "10px",
              },
              inputProps: {
                style: {
                  padding: "16px",
                },
              },
            }}
            placeholder="게임 제목을 입력해주세요."
            value={name}
            onChange={handleName}
            error={!!errors.name}
            helperText={errors.name}
          />

          <FormControl fullWidth>
            <Typography
              sx={{
                font: "bold 18px Pretendard",
                color: "#454545",
                marginLeft: "4px",
                marginBottom: "4px",
              }}
            >
              게임 모드 선택
            </Typography>
            <Select
              value={gameMode}
              onChange={handleGameModeChange}
              displayEmpty
              size="small"
              sx={{
                padding: 0,
                bgcolor: "#ddd",
                borderRadius: "10px",
              }}
            >
              {/* <MenuItem value="individual" disabled>
                개인전
              </MenuItem> */}
              <MenuItem value="team">단체전</MenuItem>
            </Select>
          </FormControl>

          {gameMode === "team" && (
            <FormControl fullWidth>
              <Typography
                sx={{
                  font: "bold 18px Pretendard",
                  color: "#454545",
                  marginLeft: "4px",
                  marginTop: "10px",
                  marginBottom: "4px",
                }}
              >
                팀 갯수 선택
              </Typography>
              <Select
                value={teamCount}
                onChange={handleTeamCountChange}
                displayEmpty
                size="small"
                sx={{
                  padding: 0,
                  bgcolor: "#ddd",
                  borderRadius: "10px",
                }}
              >
                <MenuItem value={2}>2팀</MenuItem>
                <MenuItem value={3} disabled={plan === "free"}>
                  3팀 {plan === "free" && "(유료 구독자만 사용 가능)"}
                </MenuItem>
                <MenuItem value={4} disabled={plan === "free"}>
                  4팀 {plan === "free" && "(유료 구독자만 사용 가능)"}
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            fullWidth
            sx={{
              bgcolor: "#ababab",
              "&:hover": { bgcolor: "#9a9aab" },
              boxShadow: "2px 2px 6px #ababab",
            }}
            onClick={() => {
              resetForm();
              onClose();
            }}
          >
            취소
          </Button>
          <Button fullWidth sx={{ boxShadow: "2px 2px 6px #ababab" }} onClick={handleSubmit}>
            저장하기
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

// 0810 수정 모달
export const EditTapModal = (props) => {
  const { open, onClose, getList, id } = props;
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);
  const [opacity, setOpacity] = useState(0);
  const [style, setStyle] = useState({ transform: "scale(0)", opacity: 0 });
  const [name, setName] = useState("");
  const [gameMode, setGameMode] = useState("team");
  const [teamCount, setTeamCount] = useState(2);
  const [errors, setErrors] = useState({});
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [plan, setPlan] = useState("free");

  // 기존 데이터를 불러와서 폼에 미리 채워넣기 위한 함수
  const fetchTapData = async () => {
    try {
      const res = await TAPI.GET_LIST_TAP(cookies.auth);
      const { lists } = res.data;
      const currentTap = lists.find((tap) => tap._id === id);

      if (currentTap) {
        setName(currentTap.name);
        setGameMode(currentTap.gameMode);
        setTeamCount(currentTap.teamCount || 2);
      } else {
        setOpen(true);
        setSeverity("error");
        setText("해당 탭을 찾을 수 없습니다.");
        onClose();
      }
    } catch (error) {
      setOpen(true);
      setSeverity("error");
      setText("데이터를 불러오는 중 오류가 발생했습니다.");
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      setOpacity(1);
      setStyle({ transform: "scale(1)", opacity: 1 });

      axios.get("/api/user", { params: { auth: cookies.auth } }).then((res) => {
        let { success, user } = res.data;

        setPlan(user.plan);
      });
      fetchTapData();
    } else {
      setOpacity(0);
      setStyle({ transform: "scale(0)", opacity: 0 });
    }
  }, [open]);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleGameModeChange = (e) => {
    setGameMode(e.target.value);
  };

  const handleTeamCountChange = (e) => {
    setTeamCount(e.target.value);
  };

  const handleSubmit = async () => {
    if (!name) {
      setOpen(true);
      setSeverity("error");
      setText("이름을 입력해주세요.");
      return;
    }
    try {
      await TAPI.UPDATE_TAP(cookies.auth, id, name, gameMode, gameMode === "team" ? teamCount : 0);
      setOpen(true);
      setSeverity("success");
      setText("라이브 탭이 수정되었습니다.");
      onClose();
      setTimeout(() => {
        getList();
      }, 1500);
    } catch (error) {
      setOpen(true);
      setSeverity("error");
      setText("라이브 탭 수정에 실패했습니다. 다시 시도해주세요.");
    }
  };

  return (
    <Modal
      open={open}
      sx={{
        opacity: opacity,
        transition: "opacity 0.5s",
        "& .MuiBackdrop-root": { bgcolor: "rgba(0, 0, 0, 0.25)" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "70%",
          minHeight: "700px",
          aspectRatio: "1/1.7",
          ...style,
          bgcolor: "white",
          transition: "all 1s",
          transitionDelay: "0.25s",
          borderRadius: "10px",
          padding: "10px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography sx={{ color: "#c33c3c", font: "bold 24px Pretendard" }}>
          Live Tap 수정
        </Typography>
        <Box
          sx={{
            borderRadius: "8px",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={liveTapBg} alt="Live Tap" style={{ width: "100%" }} />
        </Box>
        <Box sx={{ gap: "5px", display: "flex", flexDirection: "column", flex: 1 }}>
          <TextField
            fullWidth
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ font: "bold 20px Pretendard" }}>Name :</Typography>
                </InputAdornment>
              ),
              style: {
                fontSize: "16px",
                fontFamily: "Pretendard",
                fontWeight: 600,
                backgroundColor: "#ddd",
                borderRadius: "10px",
                color: "#454545",
                marginBottom: "10px",
              },
              inputProps: {
                style: {
                  padding: "16px",
                },
              },
            }}
            placeholder="게임 제목을 입력해주세요."
            value={name}
            onChange={handleName}
            error={!!errors.name}
            helperText={errors.name}
          />

          <FormControl fullWidth>
            <Typography
              sx={{
                font: "bold 18px Pretendard",
                color: "#454545",
                marginLeft: "4px",
                marginBottom: "4px",
              }}
            >
              게임 모드 선택
            </Typography>
            <Select
              value={gameMode}
              onChange={handleGameModeChange}
              displayEmpty
              size="small"
              sx={{
                padding: 0,
                bgcolor: "#ddd",
                borderRadius: "10px",
              }}
            >
              {/* <MenuItem value="individual" disabled>
                개인전
              </MenuItem> */}
              <MenuItem value="team">단체전</MenuItem>
            </Select>
          </FormControl>

          {gameMode === "team" && (
            <FormControl fullWidth>
              <Typography
                sx={{
                  font: "bold 18px Pretendard",
                  color: "#454545",
                  marginLeft: "4px",
                  marginTop: "10px",
                  marginBottom: "4px",
                }}
              >
                팀 갯수 선택
              </Typography>
              <Select
                value={teamCount}
                onChange={handleTeamCountChange}
                displayEmpty
                size="small"
                sx={{
                  padding: 0,
                  bgcolor: "#ddd",
                  borderRadius: "10px",
                }}
              >
                <MenuItem value={2}>2팀</MenuItem>
                <MenuItem value={3} disabled={plan === "free"}>
                  3팀 {plan === "free" && "(유료 구독자만 사용 가능)"}
                </MenuItem>
                <MenuItem value={4} disabled={plan === "free"}>
                  4팀 {plan === "free" && "(유료 구독자만 사용 가능)"}
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            fullWidth
            sx={{
              bgcolor: "#ababab",
              "&:hover": { bgcolor: "#9a9aab" },
              boxShadow: "2px 2px 6px #ababab",
            }}
            onClick={onClose}
          >
            취소
          </Button>
          <Button fullWidth sx={{ boxShadow: "2px 2px 6px #ababab" }} onClick={handleSubmit}>
            수정하기
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AddTokensModal = (props) => {
  const { open, onClose, hexagramId, getList, hexagramPoint, setHexagramPoint } = props;
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);
  const [cookies] = useCookies();
  const [opacity, setOpacity] = useState(0);
  const [style, setStyle] = useState({ transform: "scale(0)", opacity: 0 });
  const [token, setToken] = useState(0);
  const [isToken, setIsToken] = useState(false);
  const [isTokenNum, setIsTokenNum] = useState(false);
  const [remainingTokens, setRemainingTokens] = useState(0);

  const handleToken = (e) => {
    setToken(e.target.value);
    setIsToken(e.target.value.length > 0);
    setIsTokenNum(!isNaN(e.target.value));
  };

  const handleSubmit = () => {
    if (!isToken) {
      setOpen(true);
      setSeverity("error");
      setText("토큰 값을 입력해주세요.");
      return;
    }
    if (!isTokenNum) {
      setOpen(true);
      setSeverity("error");
      setText("토큰 값을 숫자로 입력해주세요.");
      return;
    }
    if (parseInt(token) > hexagramPoint) {
      setOpen(true);
      setSeverity("error");
      setText(`토큰이 부족합니다 (현재 가지고 있는 토큰: ${hexagramPoint}개)`);
      return;
    }

    HAPI.POST_ADD_TOKENS(cookies.auth, hexagramId, parseInt(token))
      .then((res) => {
        const { success, hexagramPoint: updatedHexagramPoint } = res.data;
        if (success) {
          setOpen(true);
          setSeverity("success");
          setText("토큰이 추가되었습니다.");
          onClose();
          setTimeout(() => {
            getList();
          }, 1500);
          setHexagramPoint(updatedHexagramPoint);
        } else {
          setOpen(true);
          setSeverity("error");
          setText("토큰 추가에 실패했습니다.");
        }
      })
      .catch((err) => {
        setOpen(true);
        setSeverity("error");
        setText("서버 오류가 발생했습니다.");
      });
  };

  const resetForm = () => {
    setToken(0);
    setIsToken(false);
    setIsTokenNum(false);
  };

  useEffect(() => {
    if (open) {
      setOpacity(1);
      setStyle({ transform: "scale(1)", opacity: 1 });
      HAPI.CHECK_HEXAGRAM_TOKENS(cookies.auth, hexagramId)
        .then((res) => {
          const { success, tokens } = res.data;
          if (success) {
            setRemainingTokens(tokens);
          }
        })
        .catch((err) => {
          setRemainingTokens(0);
        });
    } else {
      setOpacity(0);
      setStyle({ transform: "scale(0)", opacity: 0 });
      resetForm(); // 모달이 닫힐 때 입력 필드 초기화
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        opacity: opacity,
        transition: "opacity 0.5s",
        "& .MuiBackdrop-root": { bgcolor: "rgba(0, 0, 0, 0.25)" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "50%",
          minHeight: "550px",
          aspectRatio: "1/1.5",
          ...style,
          bgcolor: "white",
          transition: "all 1s",
          transitionDelay: "0.25s",
          borderRadius: "10px",
          padding: "10px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography sx={{ color: "#c33c3c", font: "bold 24px Pretendard" }}>
          토큰 확인 및 추가하기
        </Typography>

        <Box sx={{ gap: "20px", display: "flex", flexDirection: "column", flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "auto",
              width: "90%",
              margin: "0 auto",
            }}
          >
            <img
              src={MainImg}
              alt="Hexagram"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px 4px 0 4px",
              }}
            >
              <Typography sx={{ font: "bold 20px Pretendard", color: "#333" }}>
                추가할 토큰 개수 :
              </Typography>

              <TextField
                InputProps={{
                  style: {
                    backgroundColor: "#ddd",
                    width: "100px",
                    fontSize: "20px",
                  },
                  inputProps: {
                    style: {
                      padding: "11px",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography sx={{ font: "bold 20px Pretendard" }}>(개)</Typography>
                    </InputAdornment>
                  ),
                }}
                value={token}
                onChange={handleToken}
              />
            </Box>
            <Typography sx={{ color: "#777", fontSize: "14px", textAlign: "right" }}>
              보유한 토큰 갯수: {hexagramPoint}개
            </Typography>
            <Typography
              sx={{ color: "#777", fontSize: "14px", textAlign: "right", marginTop: "-6px" }}
            >
              해당 핵사그램에 남아있는 토큰 갯수: {remainingTokens}개
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            fullWidth
            sx={{
              bgcolor: "#ababab",
              "&:hover": { bgcolor: "#9a9aab" },
              boxShadow: "2px 2px 6px #ababab",
            }}
            onClick={() => {
              resetForm();
              onClose();
            }}
          >
            취소
          </Button>
          <Button fullWidth sx={{ boxShadow: "2px 2px 6px #ababab" }} onClick={handleSubmit}>
            추가하기
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const SMSSendingModal = (props) => {
  const { open, onClose, gameId } = props;
  const [opacity, setOpacity] = useState(0);
  const [style, setStyle] = useState({ transform: "scale(0)", opacity: 0 });
  const [sendMethod, setSendMethod] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState(""); // 이메일 상태 변수 추가
  const [senderPhone, setSenderPhone] = useState(""); // 전화번호 상태 변수 추가
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState({});
  const [smsSendedCount, setSmsSendedCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setOpacity(1);
      setStyle({ transform: "scale(1)", opacity: 1 });
      HAPI.GET_SMS_SENDED_COUNT(gameId).then((res) => {
        const { success, count } = res.data;

        if (success) {
          setSmsSendedCount(count);
        }
      });
    } else {
      setOpacity(0);
      setStyle({ transform: "scale(0)", opacity: 0 });
    }
  }, [open]);

  const handleSendMethodChange = (event) => {
    setSendMethod(event.target.value);
  };

  const validateFields = () => {
    let fieldErrors = {};
    if (!senderName) fieldErrors.senderName = "보내는 사람 성함을 입력해주세요.";
    if (sendMethod === "이메일" && !senderEmail)
      fieldErrors.senderEmail = "보내는 사람 이메일을 입력해주세요."; // 이메일 유효성 검사 추가
    if (sendMethod === "SMS" && !senderPhone)
      fieldErrors.senderPhone = "보내는 사람 전화번호를 입력해주세요."; // 전화번호 유효성 검사 추가
    // if (!senderEmail) fieldErrors.senderEmail = "보내는 사람 이메일을 입력해주세요."; // 이메일 유효성 검사 추가
    if (!subject) fieldErrors.subject = "제목을 입력해주세요.";
    if (!content) fieldErrors.content = "내용을 입력해주세요.";
    if (!sendMethod) fieldErrors.sendMethod = "보낼 방식을 선택해주세요.";
    return fieldErrors;
  };

  const handleSubmit = () => {
    const fieldErrors = validateFields();
    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      // 데이터 전송
      HAPI.POST_SEND_RESULT(
        gameId,
        sendMethod,
        senderName,
        senderEmail,
        subject,
        content,
        senderPhone
      )
        .then((res) => {
          const { success, reason, inappropriateContent, message } = res.data;

          if (!success) {
            if (!reason) {
              alert(` 전송에 실패했습니다. 다시 시도해주세요.\n 메시지: ${message}`);
              window.location.reload();
              return;
            }
            alert(
              ` 전송에 실패했습니다\n 이유 : ${reason}\n 부적절한 내용 : ${inappropriateContent}`
            );
            return;
          }
          // console.log("Submission successful: ", res);
          alert("전송이 완료되었습니다.");
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          console.error("Submission error: ", err);
        });
    } else {
      setErrors(fieldErrors);
    }
  };

  return (
    <Modal
      open={open}
      sx={{
        opacity: opacity,
        transition: "opacity 0.5s",
        "& .MuiBackdrop-root": { bgcolor: "rgba(0, 0, 0, 0.25)" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "70%",
          minHeight: "750px",
          aspectRatio: "1/1.7",
          ...style,
          bgcolor: "white",
          transition: "all 1s",
          transitionDelay: "0.25s",
          borderRadius: "10px",
          padding: "10px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography sx={{ color: "#c33c3c", font: "bold 24px Pretendard" }}>
          핵사그램 진단 요청 메일/문자 보내기
        </Typography>
        <Box sx={{ gap: "5px", display: "flex", flexDirection: "column", flex: 1 }}>
          <FormControl fullWidth>
            <Typography
              sx={{
                font: "bold 18px Pretendard",
                color: "#454545",
                marginLeft: "4px",
                marginBottom: "6px",
              }}
            >
              전송 방식
            </Typography>
            <Select
              value={sendMethod}
              onChange={handleSendMethodChange}
              displayEmpty
              size="small"
              sx={{
                padding: 0, // Remove padding from the select input box
                bgcolor: "#ddd",
                borderRadius: "10px",
              }}
            >
              <MenuItem value="" disabled>
                전송 방식을 선택하세요
              </MenuItem>
              <MenuItem value="SMS">SMS</MenuItem>
              <MenuItem value="이메일">이메일</MenuItem>
            </Select>
            {errors.sendMethod && (
              <FormHelperText
                error
                sx={{
                  marginTop: "-2px",
                  marginLeft: "4px",
                }}
              >
                {errors.sendMethod}
              </FormHelperText>
            )}
          </FormControl>
          {sendMethod === "SMS" && (
            <Typography
              sx={{ textAlign: "right", color: "#f00", fontSize: "14px", fontWeight: 500 }}
            >
              * SMS 전송은 최대 2회까지 가능합니다.
              <span style={{ color: "#777" }}> (현재 {smsSendedCount}회 사용)</span>
            </Typography>
          )}
          <Typography
            sx={{
              font: "bold 18px Pretendard",
              color: "#454545",
              marginLeft: "4px",
              marginTop: "6px",
            }}
          >
            보내는 사람
          </Typography>
          <TextField
            fullWidth
            className="br-10"
            autoComplete="off"
            InputProps={{
              style: {
                fontSize: "16px",
                fontFamily: "Pretendard",
                fontWeight: 600,
                backgroundColor: "#ddd",
                borderRadius: "10px",
                color: "#454545",
              },
              inputProps: {
                style: {
                  padding: "8px 8px",
                },
              },
            }}
            placeholder="보내는 사람 성함을 적어주세요"
            value={senderName}
            onChange={(e) => setSenderName(e.target.value)}
          />
          {errors.senderName && (
            <FormHelperText
              error
              sx={{
                marginTop: "-2px",
                marginLeft: "4px",
              }}
            >
              {errors.senderName}
            </FormHelperText>
          )}

          {sendMethod !== "" && (
            <>
              {/* 이메일 입력 필드 추가 */}
              <Typography
                sx={{
                  font: "bold 18px Pretendard",
                  color: "#454545",
                  marginLeft: "4px",
                  marginTop: "4px",
                }}
              >
                보내는 사람 {sendMethod === "이메일" ? "이메일" : "전화번호"}
              </Typography>
              <TextField
                fullWidth
                className="br-10"
                autoComplete="off"
                InputProps={{
                  style: {
                    fontSize: "16px",
                    fontFamily: "Pretendard",
                    fontWeight: 600,
                    backgroundColor: "#ddd",
                    borderRadius: "10px",
                    color: "#454545",
                  },
                  inputProps: {
                    style: {
                      padding: "8px 8px",
                    },
                  },
                }}
                placeholder={
                  sendMethod === "이메일"
                    ? `보내는 사람 이메일을 적어주세요`
                    : `발신자 연락처를 적어주세요 (ex. 01012345678)`
                }
                value={sendMethod === "이메일" ? senderEmail : senderPhone}
                onChange={
                  sendMethod === "이메일"
                    ? (e) => setSenderEmail(e.target.value)
                    : (e) => setSenderPhone(e.target.value)
                }
              />
              {errors.senderEmail && (
                <FormHelperText
                  error
                  sx={{
                    marginTop: "-2px",
                    marginLeft: "4px",
                  }}
                >
                  {errors.senderEmail}
                </FormHelperText>
              )}
            </>
          )}

          <Typography
            sx={{
              font: "bold 18px Pretendard",
              color: "#454545",
              marginLeft: "4px",
              marginTop: "18px",
            }}
          >
            제목
          </Typography>
          <TextField
            fullWidth
            className="br-10"
            autoComplete="off"
            InputProps={{
              style: {
                fontSize: "16px",
                fontFamily: "Pretendard",
                fontWeight: 600,
                backgroundColor: "#ddd",
                borderRadius: "10px",
                color: "#454545",
              },
              inputProps: {
                style: {
                  padding: "8px 8px",
                },
              },
            }}
            placeholder="메일, 문자 제목을 입력해주세요."
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          {errors.subject && (
            <FormHelperText
              error
              sx={{
                marginTop: "-2px",
                marginLeft: "4px",
              }}
            >
              {errors.subject}
            </FormHelperText>
          )}
          <Typography
            sx={{
              font: "bold 18px Pretendard",
              color: "#454545",
              marginLeft: "4px",
              marginTop: "4px",
            }}
          >
            내용
          </Typography>
          <TextField
            fullWidth
            className="br-10"
            autoComplete="off"
            InputProps={{
              style: {
                fontSize: "16px",
                fontFamily: "Pretendard",
                fontWeight: 600,
                backgroundColor: "#ddd",
                borderRadius: "10px",
                color: "#454545",
                padding: "12px 10px", // Reduced padding
              },
            }}
            placeholder="메일, 문자 내용을 입력해주세요."
            multiline
            minRows={7}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          {errors.content && (
            <FormHelperText
              error
              sx={{
                marginTop: "-2px",
                marginLeft: "4px",
              }}
            >
              {errors.content}
            </FormHelperText>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            fullWidth
            sx={{
              bgcolor: "#ababab",
              "&:hover": { bgcolor: "#9a9a9a" },
              boxShadow: "2px 2px 6px #ababab",
            }}
            onClick={onClose}
          >
            취소
          </Button>
          <LoadingButton
            fullWidth
            sx={{ boxShadow: "2px 2px 6px #ababab" }}
            variant="contained"
            onClick={handleSubmit}
            loading={loading}
          >
            전송하기
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

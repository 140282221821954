import { Box, Typography } from "@mui/material";
import * as S from "./styles";
import { lazy, Suspense, useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
// import background from "assets/images/LiveTap/background2.png";
import background from "assets/images/LiveTap_new/Intro.png";

export default (props) => {
  const { currentLiveTap, teamCount } = useContext(UserContext);
  const [preloadedBackground, setPreloadedBackground] = useState(null);
  const gameModeLabel = currentLiveTap?.gameMode === "team" ? "(단체전)" : "(개인전)";

  useEffect(() => {
    const preload = new Image();
    preload.src = background;
    preload.onload = () => {
      setPreloadedBackground(preload);
    };
  }, []);

  if (!preloadedBackground) {
    return <div>Loading...</div>;
  }

  return (
    <S.Intro>
      <Box sx={{ justifyContent: "center", width: "100%" }}>
        <Typography
          sx={{
            font: "bold clamp(14.2px, 4.5vw, 20px) Pretendard",
            mb: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {currentLiveTap?.name}
        </Typography>
      </Box>

      <Box sx={{ borderRadius: "10px", border: "3px solid #777", width: "calc(100% - 20px)" }}>
        {preloadedBackground && (
          <img
            src={preloadedBackground.src}
            alt="Main"
            style={{ width: "100%", objectFit: "cover", height: "100%" }}
          />
        )}
      </Box>

      <Typography
        sx={{
          font: "700 clamp(27px, 8.56vw, 38px) Pretendard",
          mt: "30px",
          textAlign: "center",
          color: "black",
        }}
      >
        라이브 탭 {gameModeLabel}
      </Typography>

      <Box
        onClick={props.gotoUserInfo} // 게임 시작하기 버튼 클릭 시 handleStartGame 함수 호출
        sx={{
          mt: "25px",
          p: "clamp(14.2px, 4.5vw, 20px) clamp(10.6px, 3.4vw, 15px)",
          font: "700 clamp(15.6px, 5vw, 22px) Pretendard",
          backgroundColor: "#d6e2fb",
          borderRadius: "10px",
          color: "#50586C",
          cursor: "pointer",
          transition: "all 0.3s ease",
          boxShadow: "0px 3px 6px #00000038",
          "&:hover": {
            backgroundColor: "#c6d2eb",
            transform: "scale(1.03)",
          },
          "&:active": {
            backgroundColor: "#c6d2eb",
            transform: "scale(0.97)",
          },
        }}
      >
        게임 시작하기
      </Box>
    </S.Intro>
  );
};

import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import heic2any from "heic2any";
import { v4 as uuidv4 } from "uuid";

import {
  Modal,
  Box,
  IconButton,
  Typography,
  Divider,
  Button,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import LinkIcon from "@mui/icons-material/Link";
import ImageIcon from "@mui/icons-material/Image";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { ManagerContext } from "views/LiveBoard/contexts/Manager";
import { Textfield } from "components/Textfield";
import { SnackBarContext } from "components/SnackBar/ContextAPI";
import { CustomLoadingButton as LoadingButton } from "components/Button";

import webIcon from "assets/images/webIcon.png";

import * as API from "utils/api/Board";

const baseURL = process.env.REACT_APP_LIVE_BOARD_SERVER_URL;

export const CardModal = (props) => {
  const { open, onClose } = props;
  const { gameId } = useParams();
  const { selectedCard, layout, list, setSelectedCard, sections, socket } =
    useContext(ManagerContext);
  const SnackBar = useContext(SnackBarContext);
  const ref = useRef(null);

  const [opacity, setOpacity] = useState(0);
  const [postList, setPostList] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [comment, setComment] = useState("");
  const [replyId, setReplyId] = useState(null);
  const [src, setSrc] = useState(null);

  const selectBackCard = () => {
    setSelectedCard(postList[currentIndex - 1]);
    setCurrentIndex(currentIndex - 1);
  };

  const selectForwardCard = () => {
    setSelectedCard(postList[currentIndex + 1]);
    setCurrentIndex(currentIndex + 1);
  };

  const addComment = () => {
    if (!comment) return;
    if (comment.trim() === "") {
      setComment("");
      return;
    }

    if (replyId !== null) {
      socket.emit(
        "addReply",
        {
          gameId,
          postId: selectedCard.id,
          comment,
          user: "진행자",
          commentId: replyId,
        },
        (res) => {
          if (res.success) {
            setComment("");
            setReplyId(null);

            SnackBar.setOpen(true);
            SnackBar.setSeverity("success");
            SnackBar.setText("답글이 등록되었습니다.");
          }
        }
      );
      return;
    }

    socket.emit(
      "addComment",
      { gameId, postId: selectedCard.id, comment, user: "진행자" },
      (res) => {
        if (res.success) {
          setComment("");

          SnackBar.setOpen(true);
          SnackBar.setSeverity("success");
          SnackBar.setText("댓글이 등록되었습니다.");
        }
      }
    );
  };

  const handleReply = (e) => {
    // console.log("reply");
    setReplyId(e.currentTarget.id);
    // ref.current.focus();
    ref.current.click();
  };

  useEffect(() => {
    if (open) {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  }, []);

  useEffect(() => {
    if (layout.section) {
      const sectionPost = list.filter((item) => item.section === selectedCard.section);

      setListCount(sectionPost.length);
      setCurrentIndex(sectionPost.findIndex((item) => item.id === selectedCard.id));
      setPostList(sectionPost);
    } else {
      setListCount(list.length);
      setCurrentIndex(list.findIndex((item) => item.id === selectedCard.id));
      setPostList(list);
    }
  }, []);

  useEffect(() => {
    if (!selectedCard || !selectedCard.file) return;

    fetch(`https://board.withplus.live/image?id=${selectedCard.id}`).then((res) => {
      res.blob().then((blob) => {
        const url = URL.createObjectURL(blob);

        setSrc(url);
      });
    });
  }, [selectedCard]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      disablePortal
      slotProps={{ backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0)" } } }}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          height: "100%",
          opacity,
          transition: "opacity .5s",
          position: "relative",
        }}
      >
        <IconButton onClick={onClose} sx={{ position: "absolute", top: 10, left: 10 }}>
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            position: "absolute",
            top: 5,
            left: "50%",
            transform: "translate(-50%, 0%)",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <IconButton onClick={selectBackCard} disabled={currentIndex === 0}>
            <ArrowBackIosIcon />
          </IconButton>

          <Typography sx={{ font: "600 24px Pretendard" }}>{`${
            currentIndex + 1
          } / ${listCount}`}</Typography>

          <IconButton onClick={selectForwardCard} disabled={currentIndex === listCount - 1}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        <Box sx={{ height: "100%", display: "flex" }}>
          <Box
            sx={{
              width: "70%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: "60px",
              pb: "10px",
              boxSizing: "border-box",
            }}
          >
            {!selectedCard.file ? (
              <Typography sx={{ font: "700 28px Pretendard" }}>이미지가 없습니다.</Typography>
            ) : (
              <img src={src} style={{ maxHeight: "100%", objectFit: "contain" }} />
            )}

            {selectedCard.link && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "10px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ display: "flex", gap: "10px", font: "700 20px Pretendard" }}
                  onClick={() => window.open(selectedCard.link)}
                >
                  <OpenInNewIcon /> 링크로 이동하기
                </Button>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: "30%",
              height: "100%",
              bgcolor: "#eee",
              pt: "70px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              px: "10px",
            }}
          >
            {layout.section && (
              <Typography sx={{ font: "600 18px Pretendard", color: "#777" }}>
                {"그룹 : " + sections.find((item) => item.id === selectedCard.section)?.name}
              </Typography>
            )}

            <Typography variant="h4" sx={{ font: "700 28px Pretendard", wordBreak: "break-all" }}>
              {selectedCard.title}
            </Typography>

            <Typography
              sx={{
                wordBreak: "break-all",
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
                pr: "10px",
              }}
              onClick={() => window.open(selectedCard.link)}
            >
              {selectedCard.link}
            </Typography>

            <Typography
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-all",
                font: "600 20px Pretendard",
                width: "100%",
              }}
            >
              {selectedCard.explain}
            </Typography>

            <Divider sx={{ mx: "10px", width: "calc(100% - 20px)", borderColor: "#ccc" }} />

            <Box
              sx={{
                px: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              <Box sx={{ display: "flex", gap: "5px" }}>
                <FavoriteIcon sx={{ color: "#c33c3c" }} />
                <Typography sx={{ font: "600 20px Pretendard" }}>
                  {selectedCard.likes?.length || 0}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", gap: "5px", alignItems: "center", pr: "5px" }}>
                <ChatBubbleOutlineIcon fontSize="large" sx={{ scale: "0.8", color: "#555" }} />
                <Typography sx={{ font: "600 20px Pretendard" }}>
                  {selectedCard.comments?.length || 0}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ bgcolor: "#fff", borderRadius: "10px", border: "3px solid #c33c3c" }}>
              <Textfield
                fullWidth
                size="small"
                placeholder="댓글 추가"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <Button sx={{ fontWeight: "600" }} onClick={addComment}>
                      등록
                    </Button>
                  ),
                  startAdornment: replyId !== null && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton sx={{ fontSize: "12px" }} onClick={() => setReplyId(null)}>
                        X
                      </IconButton>

                      <Typography
                        noWrap
                        sx={{
                          pr: "5px",
                          color: "#c33c3c",
                          fontSize: "12px",
                          fontWeight: 600,
                          maxWidth: "100px",
                        }}
                      >
                        {`@${
                          selectedCard.comments.find((item) => item.date + "" === replyId + "")
                            ?.user
                        }`}
                      </Typography>
                    </Box>
                  ),
                  style: { paddingRight: "0", paddingLeft: "3px" },
                  ref: ref,
                }}
              />
            </Box>

            <Box sx={{ flex: 1, overflow: "auto" }}>
              {selectedCard.comments?.map((item, index) => (
                <Box
                  sx={{
                    bgcolor: index % 2 === 0 ? "#fff" : "#f5f5f5",
                    borderRadius: "10px",
                    p: "10px",
                    mb: "10px",
                    border: replyId === item.date + "" ? "3px solid #c33c3c" : "",
                  }}
                  key={index}
                >
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Typography sx={{ font: "600 20px Pretendard", mb: "10px", flex: 1 }}>
                      {item.user}
                    </Typography>

                    <Typography sx={{ font: "500 14px Pretendard", color: "#777", pt: "6px" }}>
                      {(() => {
                        const date = new Date(item.date);
                        const now = new Date();

                        const diff = now - date;

                        if (diff < 1000 * 60) {
                          return "방금 전";
                        }

                        if (diff < 1000 * 60 * 60) {
                          return `${Math.floor(diff / 1000 / 60)}분 전`;
                        }

                        if (diff < 1000 * 60 * 60 * 24) {
                          return `${Math.floor(diff / 1000 / 60 / 60)}시간 전`;
                        }

                        if (diff < 1000 * 60 * 60 * 24 * 7) {
                          return `${Math.floor(diff / 1000 / 60 / 60 / 24)}일 전`;
                        }

                        if (diff < 1000 * 60 * 60 * 24 * 30) {
                          return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 7)}주 전`;
                        }

                        if (diff < 1000 * 60 * 60 * 24 * 365) {
                          return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 30)}개월 전`;
                        }

                        return `${date.getFullYear()}년 ${
                          date.getMonth() + 1
                        }월 ${date.getDate()}일`;
                      })()}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      font: "400 18px Pretendard",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-all",
                    }}
                  >
                    {item.comment}
                  </Typography>

                  <Button
                    sx={{
                      p: 0,
                      color: "#888",
                      fontWeight: "600",
                      pt: "5px",
                      display: "block",
                      minWidth: "0px",
                    }}
                    onClick={handleReply}
                    id={item.date}
                  >
                    답글 달기
                  </Button>

                  {item.replies && (
                    <Box>
                      <Divider sx={{ mt: "10px", borderColor: "#ddd" }} />

                      {item.replies.map((reply, index) => (
                        <Box
                          sx={{
                            borderRadius: "10px",
                            pt: "5px",
                            pl: "10px",
                            mb: "5px",
                          }}
                          key={index}
                        >
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <Typography sx={{ font: "600 16px Pretendard", mb: "10px", flex: 1 }}>
                              {reply.user}
                            </Typography>

                            <Typography
                              sx={{ font: "500 12px Pretendard", color: "#777", pt: "6px" }}
                            >
                              {(() => {
                                const date = new Date(reply.date);
                                const now = new Date();

                                const diff = now - date;

                                if (diff < 1000 * 60) {
                                  return "방금 전";
                                }

                                if (diff < 1000 * 60 * 60) {
                                  return `${Math.floor(diff / 1000 / 60)}분 전`;
                                }

                                if (diff < 1000 * 60 * 60 * 24) {
                                  return `${Math.floor(diff / 1000 / 60 / 60)}시간 전`;
                                }

                                if (diff < 1000 * 60 * 60 * 24 * 7) {
                                  return `${Math.floor(diff / 1000 / 60 / 60 / 24)}일 전`;
                                }

                                if (diff < 1000 * 60 * 60 * 24 * 30) {
                                  return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 7)}주 전`;
                                }

                                if (diff < 1000 * 60 * 60 * 24 * 365) {
                                  return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 30)}개월 전`;
                                }

                                return `${date.getFullYear()}년 ${
                                  date.getMonth() + 1
                                }월 ${date.getDate()}일`;
                              })()}
                            </Typography>
                          </Box>

                          <Typography
                            sx={{
                              font: "400 14px Pretendard",
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-all",
                            }}
                          >
                            {reply.comment}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export const DeleteModal = (props) => {
  const { open, onClose, targetId } = props;
  const { gameId } = useParams();
  const { socket } = useContext(ManagerContext);
  const SnackBar = useContext(SnackBarContext);

  const [loading, setLoading] = useState(false);

  const deletePost = (e) => {
    e.stopPropagation();
    setLoading(true);

    socket.emit("deletePost", { gameId, postId: targetId }, (res) => {
      if (res.success) {
        onClose();

        SnackBar.setOpen(true);
        SnackBar.setSeverity("success");
        SnackBar.setText("게시물이 삭제되었습니다.");
      }
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      disablePortal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          // width: "100%",
          bgcolor: "#fff",
          // height: "100%",
          // position: "relative",
          borderRadius: "10px",
          p: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ font: "700 28px Pretendard", mb: "20px" }}>
          정말 삭제하시겠습니까?
        </Typography>

        <Typography sx={{ font: "400 18px Pretendard", mb: "30px" }}>
          삭제된 게시물은 복구할 수 없습니다.
        </Typography>

        <Box sx={{ display: "flex", gap: "10px" }}>
          <LoadingButton
            sx={{
              bgcolor: "#aaa",
              color: "#fff",
              fontWeight: "700",
              "&:hover": { bgcolor: "#999" },
            }}
            onClick={onClose}
            loading={loading}
          >
            취소
          </LoadingButton>

          <LoadingButton
            sx={{
              bgcolor: "#f00",
              color: "#fff",
              fontWeight: "700",
              "&:hover": { bgcolor: "#e00" },
            }}
            onClick={deletePost}
            loading={loading}
          >
            삭제
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export const PostModal = (props) => {
  const { open, onClose } = props;

  const { gameId } = useParams();
  const imageInputRef = useRef(null);

  const { sections, targetSection, socket, modifyTarget, setModifyTarget } =
    useContext(ManagerContext);
  const SnackBar = useContext(SnackBarContext);

  const [bottom, setBottom] = useState("-470px");

  const [file, setFile] = useState(null);
  const [title, settitle] = useState("");
  const [explain, setExplain] = useState("");
  const [isReadyToUpload, setIsReadyToUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [linkModalOpened, setLinkModalOpened] = useState(false);
  const [link, setLink] = useState("");
  const [linkLoading, setLinkLoading] = useState(false);

  const handleLink = (e) => {
    setLink(e.target.value);
  };

  const uploadLink = (e) => {
    if (e) e.preventDefault();

    setLinkLoading(true);
    API.searchLink(link, (res) => {
      const { success, ...data } = res.data;

      console.log(res.data);

      if (success) {
        let imageUrl;

        if (data.image) {
          imageUrl = data.image.url;
          setFile({ name: data.site_name || data.title, src: imageUrl, type: "link", cors: true });
        } else if (data.ogImage) {
          imageUrl = data.ogImage[0].url;
          setFile({ name: data.site_name || data.title, src: imageUrl, type: "link", cors: true });
        } else {
          imageUrl = webIcon;
          setFile({ name: data.site_name || data.title, src: imageUrl, type: "link", cors: false });
        }

        if (!link.includes("http")) {
          setLink("https://" + link);
        }

        if (data.title) {
          settitle(data.title);
        }

        if (data.description) {
          setExplain(data.description);
        }

        setLinkModalOpened(false);
        setLinkLoading(false);
      } else {
        alert("링크를 불러오는데 실패했습니다.");
        setLink("");
        setLinkLoading(false);
      }
    });
  };

  const handleLinkModal = () => {
    setLinkModalOpened((prev) => !prev);
  };

  const openImageInput = () => {
    imageInputRef.current.click();
  };

  const handleImage = (e) => {
    // * 파일 사이즈가 20MB 이하인지 확인
    if (e.target.files[0].size > 20 * 1024 * 1024) {
      alert("파일 사이즈가 20MB를 초과했습니다.");
      return;
    }

    let file = e.target.files[0];

    const reader = new FileReader();

    if (file.type === "image/heic" || file.type === "image/heif") {
      heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.7,
      }).then((blob) => {
        reader.readAsDataURL(blob);

        reader.onload = (e) => {
          setFile({ name: file.name, src: e.target.result });
        };
      });
    } else {
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        setFile({ name: file.name, src: e.target.result });
      };
    }
  };

  const removeFile = () => {
    setFile(null);
    setLink("");
  };

  const handleTitle = (e) => {
    settitle(e.target.value);
  };

  const handleExplain = (e) => {
    setExplain(e.target.value);
  };

  const modifyBoard = () => {
    setLoading(true);
    let board;

    if (file && !file.cors) {
      fetch(file.src)
        .then((res) => res.blob())
        .then((blob) => {
          let type = blob.type.split("/")[1];

          API.uploadImage(blob, `${modifyTarget.id}.${type}`, (res) => {
            const { success } = res.data;

            if (success) {
              board = {
                id: modifyTarget.id,
                title,
                explain,
                file: file ? { name: file.name, src: null } : null,
                section: modifyTarget.section,
                // password,
                link: link === "" ? null : link,
              };

              socket.emit("modifyBoard", { gameId, board }, (res) => {
                console.log(res);
                if (res.success) {
                  onClose();
                  setLoading(false);

                  SnackBar.setOpen(true);
                  SnackBar.setText("게시물이 수정되었습니다.");
                  SnackBar.setSeverity("success");

                  setTimeout(() => {
                    window.location.reload();
                  }, 150);
                }
              });
            }
          });
        });
    } else if (file && file.cors) {
      board = {
        id: modifyTarget.id,
        title,
        explain,
        file: file,
        section: modifyTarget.section,
        link,
      };

      socket.emit("modifyBoard", { gameId, board, id: modifyTarget.id }, (res) => {
        if (res.success) {
          onClose();
          setLoading(false);

          SnackBar.setOpen(true);
          SnackBar.setText("게시물이 수정되었습니다.");
          SnackBar.setSeverity("success");
        }
      });
    } else {
      board = {
        id: modifyTarget.id,
        title,
        explain,
        file: file ? { name: file.name, src: null } : null,
        section: modifyTarget.section,
        // password,
      };

      socket.emit("modifyBoard", { gameId, board }, (res) => {
        if (res.success) {
          // toggleDrawer();
          onClose();
          setLoading(false);

          SnackBar.setOpen(true);
          SnackBar.setText("게시물이 수정되었습니다.");
          SnackBar.setSeverity("success");
        }
      });
    }
  };

  const postBoard = () => {
    // * 게시물 등록
    console.log("게시물 등록");
    setLoading(true);
    let uuid = localStorage.getItem("uuid");
    const timeId = new Date().getTime();
    let sectionId = targetSection || sections[0].id;
    let board;

    // console.log(sectionId);
    // return;

    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem("uuid", uuid);
    }

    if (file && !file.cors) {
      fetch(file.src)
        .then((res) => res.blob())
        .then((blob) => {
          let type = blob.type.split("/")[1];

          API.uploadImage(blob, `${uuid}_${timeId}.${type}`, (res) => {
            const { success } = res.data;

            if (success) {
              board = {
                id: `${uuid}_${timeId}`,
                title: title,
                explain: explain,
                file: file ? { name: file.name, src: null } : null,
                section: sectionId,
                link: link === "" ? null : link,
              };

              socket.emit("postBoard", { gameId, board }, (res) => {
                if (res.success) {
                  onClose();

                  SnackBar.setOpen(true);
                  SnackBar.setSeverity("success");
                  SnackBar.setText("게시물이 등록되었습니다.");
                }
              });
            }
          });
        });
    } else if (file && file.cors) {
      board = {
        id: `${uuid}_${timeId}`,
        title: title,
        explain: explain,
        file: file,
        section: sectionId,
        link,
      };

      socket.emit("postBoard", { gameId, board, id: `${uuid}_${timeId}` }, (res) => {
        if (res.success) {
          onClose();

          SnackBar.setOpen(true);
          SnackBar.setSeverity("success");
          SnackBar.setText("게시물이 등록되었습니다.");
        }
      });
    } else {
      board = {
        id: `${uuid}_${timeId}`,
        title: title,
        explain: explain,
        file: null,
        section: sectionId,
      };

      socket.emit("postBoard", { gameId, board }, (res) => {
        if (res.success) {
          onClose();

          SnackBar.setOpen(true);
          SnackBar.setSeverity("success");
          SnackBar.setText("게시물이 등록되었습니다.");
        }
      });
    }
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setBottom("0");
      }, 200);
    } else {
      setBottom("-470px");
      setModifyTarget(null);
    }
  }, [open]);

  useEffect(() => {
    if (!file && !title && !explain) {
      setIsReadyToUpload(false);
    } else {
      setIsReadyToUpload(true);
    }
  }, [file, title, explain]);

  useEffect(() => {
    if (!modifyTarget) return;

    settitle(modifyTarget.title);
    setExplain(modifyTarget.explain);

    if (modifyTarget.file) {
      fetch("https://board.withplus.live/image?id=" + modifyTarget.id).then((res) => {
        res.blob().then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);

          reader.onload = (e) => {
            setFile({ name: modifyTarget.file.name, src: e.target.result });
          };
        });
      });
    }
  }, [modifyTarget]);

  return (
    <Modal open={open} onClose={onClose} sx={{ zIndex: 10001 }} disablePortal>
      <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
        <Box
          sx={{
            bgcolor: "#fff",
            position: "absolute",
            bottom,
            right: "60px",
            width: "600px",
            height: "470px",
            borderRadius: "10px 10px 0 0",
            transition: "bottom 0.2s",
            outline: "none",
            paddingBottom: "20px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", p: "10px" }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

            <LoadingButton
              sx={{ font: "600 18px Pretendard", p: "10px 20px" }}
              disabled={!isReadyToUpload}
              onClick={modifyTarget ? modifyBoard : postBoard}
              loading={loading}
            >
              {modifyTarget ? "수정하기" : "게시하기"}
            </LoadingButton>
          </Box>

          {targetSection && (
            <Typography sx={{ font: "600 18px Pretendard", px: "10px" }}>
              {sections.find((item) => item.id === targetSection)?.name}에 게시물 작성
            </Typography>
          )}

          <Box sx={{ p: "10px" }}>
            <Textfield
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">제목 : </InputAdornment>,
              }}
              value={title}
              onChange={handleTitle}
              placeholder="제목을 입력해주세요."
            />
          </Box>

          <Box sx={{ p: "10px", flex: 1, boxSizing: "border-box" }}>
            <Box
              sx={{
                height: "100%",
                border: "2.5px dashed #e0e0e0",
                p: "10px",
                boxSizing: "border-box",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                // "&:hover": {
                //   cursor: file ? "default" : "pointer",

                //   bgcolor: file ? "#fff" : "#eee",
                // },
              }}
            >
              {!file && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    {/*  */}
                    <Tooltip
                      title="이미지 업로드"
                      PopperProps={{ disablePortal: true }}
                      arrow
                      placement="top"
                    >
                      <IconButton
                        sx={{ border: "2px solid #aaa", bgcolor: "#eee" }}
                        onClick={openImageInput}
                      >
                        <ImageIcon sx={{ fontSize: "40px" }} />
                      </IconButton>
                    </Tooltip>

                    {/* <Tooltip
                    title="파일 업로드"
                    PopperProps={{ disablePortal: true }}
                    arrow
                    placement="top"
                  >
                    <IconButton sx={{ border: "2px solid #aaa", bgcolor: "#eee" }}>
                      <UploadFileRoundedIcon sx={{ fontSize: "40px" }} />
                    </IconButton>
                  </Tooltip> */}

                    <Tooltip
                      title="URL 업로드"
                      PopperProps={{ disablePortal: true }}
                      arrow
                      placement="top"
                      onClick={handleLinkModal}
                    >
                      <IconButton sx={{ border: "2px solid #aaa", bgcolor: "#eee" }}>
                        <LinkIcon sx={{ fontSize: "40px" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Typography sx={{ font: "700 22px Pretendard" }}>이미지 / 링크 업로드</Typography>

                  {/* <Typography sx={{ font: "600 18px Pretendard" }}>
                    (최대 20MB의 jpg, png, jpeg 파일)
                  </Typography> */}

                  {/* <Typography sx={{ font: "600 16px Pretendard", color: "#777" }}>
                  지원 형식 : jpg, png, jpeg
                </Typography> */}

                  <input
                    type="file"
                    // accept=".jpg, .png, .jpeg, .ppt, .pptx, .pdf"
                    accept=".jpg, .png, .jpeg"
                    style={{ display: "none" }}
                    ref={imageInputRef}
                    // onChange={handleFile}
                    onChange={handleImage}
                  />
                </Box>
              )}

              {file && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    // bgcolor: "#f0f0f088",

                    "> img": {
                      position: "absolute",
                      height: "90%",
                      objectFit: "contain",
                    },

                    "> button": {
                      position: "absolute",
                      bottom: 5,
                      right: 5,
                      fontWeight: "600",
                    },
                  }}
                >
                  <img src={file.src} alt="file" />

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      bgcolor: "#ddd",
                      p: "5px",
                      borderRadius: "0 6px 0 0 ",
                      font: "600 14px Pretendard",
                    }}
                  >
                    {file.name}
                  </Box>

                  <Button variant="contained" size="small" onClick={removeFile}>
                    제거
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ p: "10px" }}>
            <Textfield
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">내용 : </InputAdornment>,
              }}
              multiline
              maxRows={5}
              placeholder="내용을 입력해주세요."
              value={explain}
              onChange={handleExplain}
            />
          </Box>
        </Box>

        {linkModalOpened && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              bgcolor: "#00000070",
            }}
          >
            <Box
              sx={{
                bgcolor: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: " 315px",
                boxSizing: "border-box",
                borderRadius: "10px",
                // display: "flex",
                // gap: "10px",
              }}
              component="form"
              onSubmit={uploadLink}
            >
              <Typography
                sx={{
                  font: "700 18px Pretendard",
                  p: "10px",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%, 0)",
                }}
              >
                링크 업로드
              </Typography>

              <IconButton onClick={handleLinkModal}>
                <CloseIcon />
              </IconButton>

              <Textfield
                placeholder="여기에 링크 붙여넣기 또는 직접 입력"
                sx={{
                  width: "calc(100% - 20px)",
                  border: "2px solid #f00",
                  borderRadius: "10px",
                  margin: "10px",
                  mb: "5px",
                }}
                autoFocus
                size="small"
                value={link}
                onChange={handleLink}
              />

              <LoadingButton
                variant="contained"
                sx={{ font: "600 18px Pretendard", width: "calc(100% - 20px)", margin: "10px" }}
                onClick={uploadLink}
                loading={linkLoading}
              >
                링크 업로드
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

import Box from "@mui/material/Box";
import { Modal, Typography } from "@mui/material";
import { CustomButton as Button } from "components/Button";
import ManagerPageLayout from "../Layout/ManagerPageLayout";
import { useContext, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import { GET_API_CODE, GET_LIVETAP_BY_ID } from "utils/api/Tap";
import { useCookies } from "react-cookie";
import { ManagerContext, ManagerProvider } from "../contexts/ManagerContext";
import { AudioProvider, AudioContext } from "../contexts/AudioContext";
import GameScreen from "./gameScreen";
import WaitingRoom from "./waitingRoom";
// import Result from "./result";

// import background from "assets/images/LiveTap/background2.png"; // 삭제 예정
import background from "assets/images/LiveTap_new/Intro.png";
import logo from "assets/images/wLiveLogo(Red).png";
import { FullScreenProvider } from "contexts/FullScreen";
import Result from "./result";
import Winner from "./winner";
// import { AudioProvider, AudioContext } from "contexts/Audio";
import sleep from "utils/sleep";

export default () => {
  return (
    <AudioProvider>
      <ManagerProvider>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/waitingRoom" element={<WaitingRoom />} />
          <Route path="/game" element={<GameScreen />} />
          <Route path="/winner" element={<Winner />} />
          <Route path="/result" element={<Result />} />
        </Routes>
      </ManagerProvider>
    </AudioProvider>
  );
};

const Index = () => {
  // return <Content />;
  return (
    <ManagerPageLayout title={"Live Tap"} codeOpen={false}>
      {{
        name: "Live Tap",
        ContentBox: <Content />,
        QRCodeBox: <QRCodeBox />,
      }}
    </ManagerPageLayout>
  );
};

const Content = () => {
  const boxRef = useRef(null);

  const { tap, setTap } = useContext(ManagerContext);
  const { prepareSounds, handleBGM, BGM } = useContext(AudioContext);
  // const { playBGM, bgm } = useContext(AudioContext);

  const [display, setDisplay] = useState("flex");
  const [showComponent, setShowComponent] = useState(false);
  const [currentLiveTap, setCurrentLiveTap] = useState(null);
  const [cookies] = useCookies();
  const { gameId } = useParams();
  const [teamCount, setTeamCount] = useState(1);
  const navigate = useNavigate();

  const [boxStyles, setBoxStyles] = useState({});

  // 로비로 이동하는 함수
  const goToLobby = () => {
    navigate(`/tap/manager/${gameId}/waitingRoom`);
  };

  // 게임 화면으로 이동하는 함수
  const goToGameScreen = () => {
    navigate(`/tap/manager/${gameId}/game`);
  };

  // 게임 시작 여부 확인 함수
  const checkGameStartStatus = async () => {
    if (BGM.paused) {
      handleBGM("liveTapBgm");
    }
    prepareSounds();

    // return;

    await sleep(500); // 1초 대기
    // return;
    try {
      const response = await GET_LIVETAP_BY_ID(gameId);
      const liveTapData = response.data.liveTap;
      setTap(liveTapData);

      if (liveTapData.isGameStart) {
        goToGameScreen(); // 게임이 시작되었으면 게임 화면으로 이동
      } else {
        goToLobby(); // 게임이 시작되지 않았으면 대기실로 이동
      }
    } catch (error) {
      console.error("Failed to fetch LiveTap data:", error);
    }
  };

  useEffect(() => {
    if (!tap.teamData) return;

    if (tap.teamData.length >= 2) setTeamCount(tap.teamData.length);
  }, [tap]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const boxWidth = entries[0].contentRect.width;
      const boxHeight = entries[0].contentRect.height;

      if (boxWidth / boxHeight > 16 / 9) {
        console.log("가로가 더 길어요");
        setBoxStyles({
          height: "100%",
        });
      } else {
        console.log("세로가 더 길어요");
        setBoxStyles({
          width: "100%",
        });
      }
    });

    if (boxRef.current) observer.observe(boxRef.current);

    return () => {
      boxRef.current && observer.unobserve(boxRef.current);
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      sx={{
        height: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80px",
          boxShadow: "0px 3px 6px #00000028",
          backgroundColor: "bisque",
          "> p": {
            font: "bold 45px Pretendard",
          },
        }}
      >
        <p>{tap.name}</p>
      </Box> */}
      <Box
        sx={{
          ...boxStyles,
          display,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          backgroundImage: `url(${background})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          aspectRatio: "16/9",
        }}
      >
        <Button
          sx={{
            position: "absolute",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            font: "700 26px Pretendard",
            // fontSize: "clamp(16.25px, 1.354vw, 22px)",
            fontSize: "clamp(20px, 1.67vw, 30px)",
            width: "clamp(200px, 16.67vw, 300px)",
            height: "clamp(50px, 4.17vw, 75px)",
            // borderRadius: "50%",
            // width: "clamp(75px, 6.25vw, 110px)",
            // height: "clamp(75px, 6.25vw, 110px)",
            bgcolor: "#d6e2fb",
            color: "#50586C",
            boxShadow: "4px 4px 4px #73B9C9",
            "&:hover": { bgcolor: "#c6d2eb" },
            "&:active": { boxShadow: "none", transform: "translate(-50%, 2px)" },
            // "&:active": { boxShadow: "none" },
          }}
          onClick={checkGameStartStatus}
        >
          시작하기
        </Button>
        {/* <Box
          sx={{
            bgcolor: "rgba(255, 255 ,255 ,0.4)",
            boxShadow: "0px 4px 30px rgba(0,0,0,0.3)",
            backdropFilter: "blur(7px)",
            border: "1px solid rgba(255, 255, 255, 0.3)",
            padding: "40px",
            textAlign: "center",
            borderRadius: "20px",
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              // border: "1px solid black",
              borderRadius: "10px",
              // width: "30%",
              // height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // marginTop: "80px",
              // marginBottom: "40px",
              // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              gap: "20px",
              marginBottom: "40px",
            }}
          >
            <img src={logo} alt="logo" style={{ width: "75px" }} />
            <Typography sx={{ font: "bold 60px Pretendard", color: "#333" }}>
              Withplus Live
            </Typography>
          </Box>

          <Typography sx={{ font: "bold 57px Pretendard", color: "#333", mb: "5px" }}>
            라이브 탭
          </Typography>

          <Typography sx={{ font: "600 42px Pretendard", color: "#333", mb: "40px" }}>
            {tap.gameMode === "team" && "(단체전)"}{" "}
          </Typography>

          <Button
            sx={{
              font: "700 26px Pretendard",
              // fontSize: "clamp(16.25px, 1.354vw, 22px)",
              fontSize: "clamp(20px, 1.67vw, 30px)",
              width: "clamp(200px, 16.67vw, 300px)",
              height: "clamp(50px, 4.17vw, 75px)",
              // borderRadius: "50%",
              // width: "clamp(75px, 6.25vw, 110px)",
              // height: "clamp(75px, 6.25vw, 110px)",
              bgcolor: "#d6e2fb",
              color: "#50586C",
              "&:hover": { bgcolor: "#c6d2eb" },
            }}
            onClick={checkGameStartStatus}
          >
            시작하기
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

const QRCodeBox = () => {
  const [baseURL, setBaseURL] = useState("https://www.withplus.live");
  const [accessCode, setAccessCode] = useState("123456");
  const [modalOpened, setModalOpened] = useState(false);
  const { gameId } = useParams();

  const handleQRcodeModal = () => {
    setModalOpened(!modalOpened);
  };

  useEffect(() => {
    GET_API_CODE(gameId)
      .then((res) => {
        const code = res.data.code;
        if (typeof code === "string") {
          setAccessCode(code);
        } else {
          setAccessCode(String(code));
        }
      })
      .catch((error) => {
        console.error("Error fetching access code:", error);
      });
  }, [gameId]);

  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          padding: "3px",
          borderRadius: "10px",
          height: "350px",
          boxSizing: "border-box",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          "&:hover": { transform: "scale(1.05)" },
        }}
        onClick={handleQRcodeModal}
      >
        <QRCode
          style={{
            width: "80%",
            height: "80%",
            margin: "27px 0 0 40px",
          }}
          value={baseURL + `/tap/user/${gameId}`}
          renderAs="svg"
        />
      </Box>
      <Box>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            font: "bold 28px Pretendard",
            color: "#B43F3F",
            marginBottom: "10px",
          }}
        >
          접속 코드
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            font: "bold 40px NotoSansKR-Medium",
            color: "#B43F3F",
          }}
        >
          {accessCode.slice(0, 3) + " " + accessCode.slice(3)}
        </Typography>
      </Box>

      <Modal
        open={modalOpened}
        onClose={handleQRcodeModal}
        sx={{
          "& :focus-visible": {
            outline: "none ",
          },
        }}
        disablePortal
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            cursor: "pointer",
          }}
          onClick={handleQRcodeModal}
        >
          <QRCode
            renderAs="svg"
            value={baseURL + `/tap/user/${gameId}`}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Modal>
    </>
  );
};

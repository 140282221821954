import React, { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import heic2any from "heic2any";

import { Box, IconButton, Button, InputAdornment, Typography, Modal } from "@mui/material";
import { Masonry } from "@mui/lab";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import LinkIcon from "@mui/icons-material/Link";
import ImageIcon from "@mui/icons-material/Image";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { UserContext, UserProvider } from "views/LiveBoard/contexts/User";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

import Card from "./components/Card";
import GroupHeader from "./components/GroupHeader";
import { Textfield } from "components/Textfield";
import { CustomLoadingButton as LoadingButton } from "components/Button";

import * as S from "./styles";
import * as API from "utils/api/Board";

import logo from "assets/images/wLiveLogo(Red).png";
import webIcon from "assets/images/webIcon.png";
import { CardModal } from "./components/Modal";

export default () => {
  return (
    <UserProvider>
      <Main />
    </UserProvider>
  );
};

const Main = () => {
  const { gameId } = useParams();
  const imageInputRef = useRef(null);
  const {
    socket,
    board,
    layout,
    title: name,
    explain: subTitle,
    // sections,
    modifyTarget,
    selectedCard,
    isCardModalOpened,
    handleCardModal,
    setModifyTarget,
  } = useContext(UserContext);
  const Snackbar = useContext(SnackBarContext);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [explain, setExplain] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [password, setPassword] = useState("");
  const [linkModalOpened, setLinkModalOpened] = useState(false);
  const [link, setLink] = useState("");
  const [linkLoading, setLinkLoading] = useState(false);

  const toggleDrawer = (e) => {
    const sectionId = e?.currentTarget.getAttribute("section-id");

    setSectionId(sectionId || null);
    setOpen(!open);
  };

  const openImageInput = () => {
    imageInputRef.current.click();
  };

  const handleLinkModal = () => {
    setLinkModalOpened(!linkModalOpened);
  };

  const handleLink = (e) => {
    setLink(e.target.value);
  };

  const uploadLink = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setLinkLoading(true);

    API.searchLink(link, (res) => {
      const { success, ...data } = res.data;

      if (success) {
        let imageUrl;

        if (data.image) {
          imageUrl = data.image.url;
          setFile({ name: data.site_name || data.title, src: imageUrl, type: "link", cors: true });
        } else if (data.ogImage) {
          imageUrl = data.ogImage[0].url;
          setFile({ name: data.site_name || data.title, src: imageUrl, type: "link", cors: true });
        } else {
          imageUrl = webIcon;
          setFile({ name: data.site_name || data.title, src: imageUrl, type: "link", cors: false });
        }

        if (!link.includes("http")) {
          setLink("https://" + link);
        }

        if (data.title) {
          setTitle(data.title);
        }

        if (data.description) {
          setExplain(data.description);
        }

        setLinkModalOpened(false);
        setLinkLoading(false);
      } else {
        alert("링크를 불러오는데 실패했습니다.");
        setLink("");
        setLinkLoading(false);
      }
    });
  };

  const handleFile = (e) => {
    // * 파일 사이즈가 20MB 이하인지 확인
    if (e.target.files[0].size > 20 * 1024 * 1024) {
      alert("파일 사이즈가 20MB를 초과했습니다.");
      return;
    }

    let file = e.target.files[0];

    const reader = new FileReader();

    if (file.type === "image/heic" || file.type === "image/heif") {
      heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.7,
      }).then((blob) => {
        reader.readAsDataURL(blob);

        reader.onload = (e) => {
          setFile({ name: file.name, src: e.target.result });
        };
      });
    } else {
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        setFile({ name: file.name, src: e.target.result });
      };
    }
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleExplain = (e) => {
    setExplain(e.target.value);
  };

  const handlePassword = (e) => {
    const value = e.target.value;

    if (isNaN(value * 1)) {
      return;
    }

    setPassword(e.target.value);
  };

  const removeFile = () => {
    setFile(null);
    setLink("");
  };

  const modifyBoard = () => {
    console.log("modifyBoard");
    setLoading(true);
    let board;

    if (file && !file.cors) {
      fetch(file.src)
        .then((res) => res.blob())
        .then((blob) => {
          let type = blob.type.split("/")[1];

          API.uploadImage(blob, `${modifyTarget.id}.${type}`, (res) => {
            const { success } = res.data;

            if (success) {
              board = {
                id: modifyTarget.id,
                title,
                explain,
                file: file ? { name: file.name, src: null } : null,
                section: sectionId,
                password,
                link: link === "" ? null : link,
              };

              socket.emit("modifyBoard", { gameId, board }, (res) => {
                if (res.success) {
                  toggleDrawer();
                  setLoading(false);

                  Snackbar.setOpen(true);
                  Snackbar.setText("게시물이 수정되었습니다.");
                  Snackbar.setSeverity("success");

                  setLink("");
                }
              });
            }
          });
        });
    } else if (file && file.cors) {
      board = {
        id: modifyTarget.id,
        title,
        explain,
        file: file,
        section: sectionId,
        link,
      };

      socket.emit("modifyBoard", { gameId, board, id: modifyTarget.id }, (res) => {
        if (res.success) {
          toggleDrawer();
          setLoading(false);

          Snackbar.setOpen(true);
          Snackbar.setText("게시물이 수정되었습니다.");
          Snackbar.setSeverity("success");

          setLink("");
        }
      });
    } else {
      board = {
        id: modifyTarget.id,
        title,
        explain,
        file: file ? { name: file.name, src: null } : null,
        section: sectionId,
        password,
      };

      socket.emit("modifyBoard", { gameId, board }, (res) => {
        if (res.success) {
          toggleDrawer();
          setLoading(false);

          Snackbar.setOpen(true);
          Snackbar.setText("게시물이 수정되었습니다.");
          Snackbar.setSeverity("success");

          setLink("");
        }
      });
    }
  };

  const postBoard = () => {
    setLoading(true);
    const uuid = localStorage.getItem("uuid");
    const timeId = new Date().getTime();
    let board;

    if (file && !file.cors) {
      fetch(file.src)
        .then((res) => res.blob())
        .then((blob) => {
          let type = blob.type.split("/")[1];

          API.uploadImage(blob, `${uuid}_${timeId}.${type}`, (res) => {
            const { success } = res.data;

            if (success) {
              board = {
                id: `${uuid}_${timeId}`,
                title: title,
                explain: explain,
                file: file ? { name: file.name, src: null } : null,
                section: sectionId,
                password,
                link: link === "" ? null : link,
              };

              socket.emit("postBoard", { gameId, board }, (res) => {
                if (res.success) {
                  toggleDrawer();
                  setLoading(false);

                  Snackbar.setOpen(true);
                  Snackbar.setText("게시가 완료되었습니다.");
                  Snackbar.setSeverity("success");

                  setLink("");
                }
              });
            }
          });
        });
    } else if (file && file.cors) {
      board = {
        id: `${uuid}_${timeId}`,
        title: title,
        explain: explain,
        file: file,
        section: sectionId,
        link,
        password,
      };

      socket.emit("postBoard", { gameId, board, id: `${uuid}_${timeId}` }, (res) => {
        if (res.success) {
          toggleDrawer();
          setLoading(false);

          Snackbar.setOpen(true);
          Snackbar.setText("게시가 완료되었습니다.");
          Snackbar.setSeverity("success");

          setLink("");
        }
      });
    } else {
      board = {
        id: `${uuid}_${timeId}`,
        title: title,
        explain: explain,
        file: file ? { name: file.name, src: null } : null,
        section: sectionId,
        password,
      };

      socket.emit("postBoard", { gameId, board }, (res) => {
        if (res.success) {
          toggleDrawer();
          setLoading(false);

          Snackbar.setOpen(true);
          Snackbar.setText("게시가 완료되었습니다.");
          Snackbar.setSeverity("success");

          setLink("");
        }
      });
    }
  };

  useEffect(() => {
    if (!title && !explain && !file) {
      setSubmitButtonDisabled(true);
    } else {
      if (!password || password.length < 4) {
        setSubmitButtonDisabled(true);
      } else {
        setSubmitButtonDisabled(false);
      }
    }
  }, [title, explain, file, password]);

  useEffect(() => {
    const uuid = localStorage.getItem("uuid");

    // * UUID가 없으면 생성
    if (!uuid) {
      localStorage.setItem("uuid", uuidv4());
    }
  }, []);

  useEffect(() => {
    if (!open) {
      setTitle("");
      setExplain("");
      setFile(null);
      setPassword("");
      setModifyTarget(null);
    }
  }, [open]);

  useEffect(() => {
    if (!modifyTarget) return;

    setOpen(true);
    setTitle(modifyTarget.title);
    setExplain(modifyTarget.explain);
    setPassword(modifyTarget.password);

    if (modifyTarget.file) {
      fetch("https://board.withplus.live/image?id=" + modifyTarget.id).then((res) => {
        res.blob().then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);

          reader.onload = (e) => {
            setFile({ name: modifyTarget.file.name, src: e.target.result });
          };
        });
      });
    }

    if (layout?.section) {
      setSectionId(modifyTarget.section);
    }
  }, [modifyTarget]);

  return (
    <S.Body>
      <S.container disableGutters maxWidth="xs">
        <S.Logo>
          <img src={logo} alt="logo" />

          <Typography>
            {"Withplus Live - "}

            <span>라이브 보드</span>
          </Typography>
        </S.Logo>

        <Box sx={{ pl: "10px", pt: "10px" }}>
          <Typography sx={{ fontWeight: 700, fontSize: "24px", color: "#222" }}>{name}</Typography>
          <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#797979" }}>
            {subTitle}
          </Typography>
        </Box>

        <Box sx={{ flex: 1, overflow: "auto" }}>
          {layout?.section && <Group toggleDrawer={toggleDrawer} />}

          {!layout?.section && <Wall />}
        </Box>

        {!layout?.section && (
          <S.AddButton onClick={toggleDrawer}>
            <AddIcon />
          </S.AddButton>
        )}
      </S.container>

      <S.Drawer anchor="bottom" open={open} onClose={toggleDrawer} sx={{ position: "relative" }}>
        <S.PostingBox>
          <Box className="posting-header">
            <IconButton onClick={toggleDrawer}>
              <CloseIcon />
            </IconButton>

            {!modifyTarget && (
              <LoadingButton
                variant="contained"
                disabled={submitButtonDisabled}
                onClick={postBoard}
                loading={loading}
              >
                게시하기
              </LoadingButton>
            )}

            {modifyTarget && (
              <LoadingButton
                variant="contained"
                disabled={submitButtonDisabled}
                onClick={modifyBoard}
                loading={loading}
              >
                수정하기
              </LoadingButton>
            )}
          </Box>

          {sectionId && (
            <Typography
              sx={{ fontWeight: 700, fontSize: "18px", color: "#222", mb: "10px", pl: "10px" }}
            >
              그룹 - {board?.sections.find((section) => section.id === sectionId).name}에 게시하기
            </Typography>
          )}

          <Box className="posting-body">
            {!modifyTarget && (
              <Box>
                <Textfield
                  fullWidth
                  size="small"
                  type="password"
                  placeholder="비밀번호를 입력해주세요. (4~6자리)"
                  inputProps={{ inputMode: "numeric", maxLength: 6 }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">비밀번호* : </InputAdornment>,
                  }}
                  value={password}
                  onChange={handlePassword}
                />
              </Box>
            )}

            <Textfield
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">제목 : </InputAdornment>,
              }}
              value={title}
              onChange={handleTitle}
              placeholder="제목을 입력해주세요."
            />

            <S.ImageBox>
              {!file && (
                // <Box className="image-input" onClick={openImageInput}>
                //   <UploadFileRoundedIcon />

                //   <Typography className="title">클릭하여 파일 업로드</Typography>

                //   <Typography className="explain">(최대 20MB의 jpg, png 파일)</Typography>

                // <input
                //   type="file"
                //   accept=".jpg, .png, .jpeg"
                //   ref={imageInputRef}
                //   onChange={handleFile}
                // />
                // </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2.5px",
                      }}
                    >
                      <IconButton
                        onClick={openImageInput}
                        sx={{ border: "2px solid #aaa", bgcolor: "#eee" }}
                      >
                        <ImageIcon sx={{ fontSize: "40px" }} />
                      </IconButton>

                      <Typography sx={{ font: "600 14px Pretendard", color: "#777" }}>
                        이미지
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2.5px",
                      }}
                    >
                      <IconButton
                        onClick={handleLinkModal}
                        sx={{ border: "2px solid #aaa", bgcolor: "#eee" }}
                      >
                        <LinkIcon sx={{ fontSize: "40px" }} />
                      </IconButton>

                      <Typography sx={{ font: "600 14px Pretendard", color: "#777" }}>
                        링크
                      </Typography>
                    </Box>
                  </Box>

                  <Typography sx={{ font: "700 19px Pretendard" }}>이미지 / 링크 업로드</Typography>

                  {/* <Typography sx={{ font: "600 15px Pretendard" }}>
                    (최대 20MB의 jpg, png, jpeg 파일)
                  </Typography> */}

                  <input
                    type="file"
                    accept=".jpg, .png, .jpeg"
                    ref={imageInputRef}
                    style={{ display: "none" }}
                    onChange={handleFile}
                  />
                </Box>
              )}

              {file && (
                <Box className="image-preview">
                  <img src={file.src} alt="file" />

                  <Button variant="contained" size="small" onClick={removeFile}>
                    제거
                  </Button>
                </Box>
              )}
            </S.ImageBox>

            <Textfield
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">내용 : </InputAdornment>,
              }}
              multiline
              maxRows={5}
              placeholder="내용을 입력해주세요."
              value={explain}
              onChange={handleExplain}
            />
          </Box>
        </S.PostingBox>

        {linkModalOpened && (
          <Modal open={linkModalOpened} onClose={handleLinkModal}>
            <Box
              sx={{
                bgcolor: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "315px",
                borderRadius: "10px",
              }}
              component="form"
              onSubmit={uploadLink}
            >
              <Typography
                sx={{
                  font: "700 18px Pretendard",
                  p: "10px",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%, 0)",
                }}
              >
                링크 업로드
              </Typography>

              <IconButton onClick={handleLinkModal}>
                <CloseIcon />
              </IconButton>

              <Textfield
                placeholder="여기에 링크 붙여넣기 또는 직접 입력"
                sx={{
                  width: "calc(100% - 20px)",
                  border: "2px solid #f00",
                  borderRadius: "10px",
                  margin: "10px",
                  mb: "5px",
                }}
                size="small"
                value={link}
                onChange={handleLink}
              />

              <LoadingButton
                sx={{ font: "600 18px Pretendard", width: "calc(100% - 20px)", margin: "10px" }}
                onClick={uploadLink}
                loading={linkLoading}
              >
                링크 업로드
              </LoadingButton>
            </Box>
          </Modal>
        )}
      </S.Drawer>

      {isCardModalOpened && (
        <CardModal open={isCardModalOpened} item={selectedCard} onClose={handleCardModal} />
      )}
    </S.Body>
  );
};

const Wall = () => {
  const { board } = useContext(UserContext);

  const [list, setList] = useState([]);

  useEffect(() => {
    if (!board) return;

    setList(board.list);
  }, [board]);
  return (
    <Box>
      <Masonry columns={2} spacing={0}>
        {list.map((item, index) => (
          <Box sx={{ px: "2.5px" }} key={item.id}>
            <Card item={item} />
          </Box>
        ))}
      </Masonry>
    </Box>
  );
};

const Group = ({ toggleDrawer }) => {
  const { board } = useContext(UserContext);

  const [postList, setPostList] = useState([]);

  useEffect(() => {
    if (!board) return;

    const { sections, list } = board;

    if (!sections || sections.length === 0) return;

    let newPostList = [];

    for (let section of sections) {
      let sectionList = list.filter((item) => item.section === section.id);

      newPostList.push({ ...section, list: sectionList });
    }

    setPostList(newPostList);
  }, [board]);

  return (
    <Box>
      {postList.map((section) => (
        <Box key={section.id} sx={{ mb: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Box sx={{ width: "50%" }}>
              <GroupHeader section={section} />
            </Box>

            <Button
              sx={{ bgcolor: "#bbb", color: "#fff" }}
              onClick={toggleDrawer}
              section-id={section.id}
            >
              <AddIcon />

              <Typography sx={{ fontWeight: 600 }}>게시물 등록하기</Typography>
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "10px",
              overflow: "auto",
              p: "10px",
              bgcolor: "#eee",
              borderRadius: "8px",
              borderTopLeftRadius: "0",
              boxShadow: "inset 0 0 8px #00000033",
            }}
          >
            {section.list.map((item, index) => (
              <Box key={item.id} sx={{ flexShrink: 0, width: "60%" }}>
                <Card item={item} />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
